import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DeepMap, FieldError, FieldValues, useFormContext } from 'react-hook-form';

import AvailabilityTimeInput from 'components/modals/ManageAvailability/TimePeriod/AvailabilityTimeInput';
import { DateFormat } from 'enums/dateFormats';

import { TimeInputFormValues } from './timeInput.types';

const TimeInput: React.FC<{
  className?: string;
  errors?: DeepMap<FieldValues, FieldError>;
}> = ({ className, errors }) => {
  const { trigger } = useFormContext<TimeInputFormValues>();

  const wrapperClasses = classNames(
    'flex items-center justify-between self-end rounded-lg px-[7.5px] py-[10px] ',
    className ?? 'w-10/12',
    !!errors ? 'ring-red ring-2' : 'ring-gray-400 ring-1',
  );

  return (
    <div data-testid="time_scope" className={wrapperClasses}>
      <div className="relative flex items-center">
        <Common.Icon name="clock" className="size-4 flex-none text-gray-500" />
        <div className="flex">
          <AvailabilityTimeInput
            name={`dueDate.time`}
            validate={{
              required: (value) => value !== '' || 'Time is required',
            }}
            testId={`dueDate.time`}
            onBlur={() => trigger([`dueDate.time`])}
            wrapperClassNames="border-none"
          />
        </div>
      </div>

      <span className="text-sm text-gray-500">{dayjs().tz(dayjs.tz.guess()).format(DateFormat.z)}</span>
    </div>
  );
};

export default TimeInput;

import cloneDeep from 'lodash/cloneDeep';
import upperFirst from 'lodash/upperFirst';

import { PROBLEM_TYPE_FILTER } from 'components/modals/CustomerFeedbackFilters/customerFeedbackFiltersForm.settings';
import { URGENCY_TASK_FILTER } from 'components/modals/NotificationsFiltersForm/NotificationsFiltersForm.settings';
import {
  ISSUE_DESCRIPTION_FILTER,
  ISSUE_TYPE_FILTER,
} from 'components/modals/StaffFeedbackFiltersForm/staffFeedbackFiltersForm.settings';
import { MEMBERSHIP_PROSPECT } from 'constants/prospect';
import { AUDIENCE_OPTIONS, FUTURE_TASKS_FILTERS } from 'constants/taskFilter';
import { QueueTaskStatuses, QueueTaskStatusLabels } from 'enums/taskCategories';
import { OnboardingDetailsLabel, OnboardingStatus } from 'enums/taskDetailsStatus';
import { formatCategoryLabel } from 'utils/formatCategoryLabel';

import { AppliedFilterProps, TaskTypes } from './appliedFiltersTags.type';

export const camelToFlat = (camelCaseString: string) => {
  const splittedString = camelCaseString.replace(/[A-Z]/g, ' $&').trimStart().toLowerCase();
  return splittedString.charAt(0).toUpperCase() + splittedString.slice(1);
};

const FILTER_LABEL_MAP = {
  patientNames: 'Patient',
  assignedToNames: 'Assign to',
  searchKey: 'Search',
  doctorName: 'Physician',
  criterionField: 'Based on',
  createdAt: 'Creation Date',
  displayName: 'Appointment type',
  'audience.shortCode': 'Audience',
  'author.name': 'Author',
  alertAmount: 'Amount',
  shortCode: 'Shortcode',
  lastLogin: 'Last active',
  activeStates: 'Licensed in',
  'userType.name': 'User type',
  _id: 'Unique ID',
  triggerType: 'Type',
  triggerUnit: 'Units',
  'triggerTime.timeInHours': 'Timing (amount)',
  futureTasksRange: 'Future tasks',
  patientName: 'Name',
  phone: 'Phone',
  planId: 'Plan',
  prospectStatus: 'Status',
  category: 'Category',
  alertUnit: 'Unit',
  eventKey: 'Category',
  startDate: 'Date from',
  endDate: 'Date to',
  urgencyTask: 'Urgency',
  providerName: 'Physician',
  patientStatuses: 'Details',
  dynamicStatus: 'Details',
  tags: 'Patient tag',
  onboardingStatuses: 'Onboarding',
  patientTags: 'Tags',
  taskTags: 'Task Tags',
  audiences: 'Audience',
};

const getFilterLabel = (filter: AppliedFilterProps) => {
  switch (filter.label) {
    case 'category':
      return Array.isArray(filter.value) && filter.value.length > 1 ? 'Categories' : 'Category';
    case 'patientNames':
      return Array.isArray(filter.value) && filter.value.length > 1 ? 'Patients' : 'Patient';
    default:
      return (FILTER_LABEL_MAP as FilterLabels)?.[filter.label];
  }
};

interface FilterLabels {
  [key: string]: unknown;
}

export function normalizeFilterLabel(filter: AppliedFilterProps) {
  // NOTE: Filters tags names should correspond with inputs labels.
  // As url query parameters differ a little we should map them before
  if ((FILTER_LABEL_MAP as FilterLabels)[filter.label]) {
    return getFilterLabel(filter);
  }
  return camelToFlat(filter.label);
}

const normalizeFilterValue = (filterValue: unknown) => {
  if (Array.isArray(filterValue)) {
    const updatedLabels = filterValue.map((value) => formatCategoryLabel(value));
    return updatedLabels.join(', ');
  }
  return typeof filterValue === 'string' ? upperFirst(formatCategoryLabel(filterValue)) : filterValue;
};

export const presentFilter = (filter: AppliedFilterProps) => {
  const label = normalizeFilterLabel(filter);
  const value = normalizeFilterValue(filter.value);
  return `${label}: ${value}`;
};

const normalizeDateRangeFilter = (
  appliedFilters: AppliedFilterProps[],
  startLabel: string,
  endLabel: string,
  normalizedLabel: string,
): AppliedFilterProps | undefined => {
  const startFilterIndex = appliedFilters.findIndex((filter) => filter.label === startLabel);
  const endFilterIndex = appliedFilters.findIndex((filter) => filter.label === endLabel);

  if (startFilterIndex !== -1) {
    const value =
      endFilterIndex !== -1
        ? `${appliedFilters[startFilterIndex].value} ${appliedFilters[endFilterIndex].value}`
        : appliedFilters[startFilterIndex].value;

    return {
      label: normalizedLabel,
      value,
    };
  }

  return undefined;
};

const transformTaskTag = (taskTag: string) => {
  switch (taskTag) {
    case TaskTypes.nutrition:
      return 'Nutrition';
    case TaskTypes.weightManagement:
      return 'Weight Management';
    default:
      return taskTag;
  }
};

const normalizeUnitsFilter = (
  // NOTE: We have couple of inputs on Filters forms called Units.
  // It is one select input that produces 2 query parameters
  // We need parse those query parameters and to pass default value to that select input -
  // we should first normilize data so value we pass is exactly the same as one of ALERT_UNIT_OPTIONS options passed to input
  // So value we return here is label from one of ALERT_UNIT_OPTIONS

  appliedFilters: AppliedFilterProps[],
  unitLabel: string,
  orderLabel: string,
  normalizedLabel: string,
): [AppliedFilterProps, number] | [null, number] => {
  const unitFilterIndex = appliedFilters.findIndex((filter) => filter.label === unitLabel);
  const orderFilterIndex = appliedFilters.findIndex((filter) => filter.label === orderLabel);

  if (unitFilterIndex === -1) return [null, unitFilterIndex];

  const hours =
    appliedFilters[unitFilterIndex].value === 'hrs' || appliedFilters[unitFilterIndex].value === 'hours' ? 'Hours' : '';
  const minutes =
    appliedFilters[unitFilterIndex].value === 'min' || appliedFilters[unitFilterIndex].value === 'minutes'
      ? 'Mins'
      : '';
  const value = `${hours || minutes} (${appliedFilters[orderFilterIndex].value})`;

  return [
    {
      label: normalizedLabel,
      value,
    },
    unitFilterIndex,
  ];
};

export const normalizeAppliedFilters = (
  appliedFilters: AppliedFilterProps[],
  tagsOptions?: { label: string; value: string }[],
  teamsOptions?: { label: string; value: string }[],
) => {
  const copiedFilters = cloneDeep(appliedFilters);

  const createFilterValue = normalizeDateRangeFilter(copiedFilters, 'startCreateDate', 'endCreateDate', 'createdDate');
  if (createFilterValue) {
    copiedFilters.push(createFilterValue);
  }

  const dueFilterValue = normalizeDateRangeFilter(copiedFilters, 'startDueDate', 'endDueDate', 'dueDate');
  if (dueFilterValue) {
    copiedFilters.push(dueFilterValue);
  }

  const [unitFilterValue, unitFilterIndex] = normalizeUnitsFilter(
    copiedFilters,
    'alertUnit',
    'occurenceOrder',
    'alertUnit',
  );
  if (unitFilterValue) {
    copiedFilters[unitFilterIndex] = unitFilterValue;
  }

  const [triggerUnitFilterValue, triggerUnitFilterIndex] = normalizeUnitsFilter(
    copiedFilters,
    'triggerUnit',
    'triggerTime.occurenceOrder',
    'triggerUnit',
  );
  if (triggerUnitFilterValue) {
    copiedFilters[triggerUnitFilterIndex] = triggerUnitFilterValue;
  }

  const futureTaskRangeFilter = copiedFilters.findIndex((filter) => filter.label === 'futureTasksRange');
  if (futureTaskRangeFilter !== -1) {
    const appliedFutureTaskOption = FUTURE_TASKS_FILTERS.find(
      (item) => item.value === copiedFilters[futureTaskRangeFilter].value,
    );
    copiedFilters[futureTaskRangeFilter] = {
      label: 'futureTasksRange',
      value: appliedFutureTaskOption?.label ?? '',
    };
  }

  const patientStatusesFilter = copiedFilters.findIndex((filter) => filter.label === 'patientStatuses');
  if (patientStatusesFilter !== -1) {
    const patientStatusesOptions = copiedFilters[patientStatusesFilter].value
      .toString()
      .split(',')
      .map((filterValue) => QueueTaskStatusLabels[filterValue as QueueTaskStatuses])
      .join(', ');
    copiedFilters[patientStatusesFilter] = {
      label: 'patientStatuses',
      value: patientStatusesOptions ?? '',
    };
  }

  const onboardingStatuses = copiedFilters.findIndex((filter) => filter.label === 'onboardingStatuses');
  if (onboardingStatuses !== -1) {
    const onboardingFiltersLabels = {
      ...OnboardingDetailsLabel,
      [OnboardingStatus.INSURANCE_COVERED]: 'Insurance: Complete - Covered',
      [OnboardingStatus.INSURANCE_NOT_COVERED]: 'Insurance: Complete - Not covered',
      [OnboardingStatus.INSURANCE_PA_REQUIRED]: 'Insurance: PA required',
      [OnboardingStatus.INSURANCE_PA_REQUESTED]: 'Insurance: PA requested',
      [OnboardingStatus.INSURANCE_PLAN_INACTIVE]: 'Insurance: Plan inactive',
      [OnboardingStatus.INSURANCE_WAITING_BENEFITS]: 'Insurance: Waiting for benefits',
    };
    const onboardingStatusesOptions = copiedFilters[onboardingStatuses].value
      .toString()
      .split(',')
      .map((filterValue) => onboardingFiltersLabels[filterValue as OnboardingStatus])
      .join(', ');
    copiedFilters[onboardingStatuses] = {
      label: 'onboardingStatuses',
      value: onboardingStatusesOptions ?? '',
    };
  }

  const dynamicStatusFilter = copiedFilters.findIndex((filter) => filter.label === 'dynamicStatus');
  if (dynamicStatusFilter !== -1) {
    const dynamicStatusOptions =
      QueueTaskStatusLabels[copiedFilters[dynamicStatusFilter].value.toString() as QueueTaskStatuses];
    copiedFilters[dynamicStatusFilter] = {
      label: 'dynamicStatus',
      value: dynamicStatusOptions ?? '',
    };
  }

  const problemTypeFilter = copiedFilters.findIndex((filter) => filter.label === 'problemType');
  if (problemTypeFilter !== -1) {
    const problemTypeOptions = PROBLEM_TYPE_FILTER.find(
      (problemType) => problemType.value === copiedFilters[problemTypeFilter].value,
    )?.label;
    copiedFilters[problemTypeFilter] = {
      label: 'problemType',
      value: problemTypeOptions ?? '',
    };
  }

  const issueTypeFilter = copiedFilters.findIndex((filter) => filter.label === 'issueType');
  if (issueTypeFilter !== -1) {
    const issueTypeOptions = ISSUE_TYPE_FILTER.find(
      (issueType) => issueType.value === copiedFilters[issueTypeFilter].value,
    )?.label;
    copiedFilters[issueTypeFilter] = {
      label: 'issueType',
      value: issueTypeOptions ?? '',
    };
  }

  const issueDescriptionsFilter = copiedFilters.findIndex((filter) => filter.label === 'issueDescriptions');
  if (issueDescriptionsFilter !== -1) {
    const issueDescriptionsValue = copiedFilters[issueDescriptionsFilter].value;
    const issueDescriptionsOptions = (
      Array.isArray(issueDescriptionsValue) ? issueDescriptionsValue : [issueDescriptionsValue]
    ).map(
      (issueDescription) => ISSUE_DESCRIPTION_FILTER.find((issue) => issue.value === issueDescription)?.label || '',
    );
    copiedFilters[issueDescriptionsFilter] = {
      label: 'issueDescriptions',
      value: issueDescriptionsOptions ?? '',
    };
  }

  const membershipPlanFilter = copiedFilters.findIndex((filter) => filter.label === 'planId');
  if (membershipPlanFilter !== -1) {
    const appliedMembershipOption = MEMBERSHIP_PROSPECT.find(
      (item) => item.value.toString() === copiedFilters[membershipPlanFilter].value,
    );
    copiedFilters[membershipPlanFilter] = {
      label: 'planId',
      value: appliedMembershipOption?.label ?? '',
    };
  }

  const urgencyTaskFilter = copiedFilters.findIndex((filter) => filter.label === 'urgencyTask');
  if (urgencyTaskFilter !== -1) {
    const urgencyTaskOption = URGENCY_TASK_FILTER.find(
      (item) => item.value.toString() === copiedFilters[urgencyTaskFilter].value,
    );
    copiedFilters[urgencyTaskFilter] = {
      label: 'urgencyTask',
      value: urgencyTaskOption?.label ?? '',
    };
  }

  const patientTagsFilterIndex = copiedFilters.findIndex((filter) => filter.label === 'tags');
  if (patientTagsFilterIndex !== -1) {
    const patientTagsFilter = copiedFilters[patientTagsFilterIndex];

    if (patientTagsFilter?.value && Array.isArray(patientTagsFilter.value)) {
      const result = tagsOptions?.filter((item) => patientTagsFilter.value.includes(item.value));
      copiedFilters[patientTagsFilterIndex] = {
        label: 'tags',
        value: result?.map((item) => item.label) ?? '',
      };
    } else if (typeof patientTagsFilter?.value === 'string') {
      const result = tagsOptions?.find((item) => patientTagsFilter.value === item.value);
      copiedFilters[patientTagsFilterIndex] = {
        label: 'tags',
        value: result?.label ?? '',
      };
    }
  }

  const patientTagsFilterOnTasksIndex = copiedFilters.findIndex((filter) => filter.label === 'patientTags');
  if (patientTagsFilterOnTasksIndex !== -1) {
    const patientTagsOnTasksFilter = copiedFilters[patientTagsFilterOnTasksIndex];

    if (patientTagsOnTasksFilter?.value && Array.isArray(patientTagsOnTasksFilter.value)) {
      const result = tagsOptions?.filter((item) => patientTagsOnTasksFilter.value.includes(item.value));
      copiedFilters[patientTagsFilterOnTasksIndex] = {
        label: 'patientTags',
        value: result?.map((item) => item.label) ?? '',
      };
    } else if (typeof patientTagsOnTasksFilter?.value === 'string') {
      const result = tagsOptions?.find((item) => patientTagsOnTasksFilter.value === item.value);
      copiedFilters[patientTagsFilterOnTasksIndex] = {
        label: 'patientTags',
        value: result?.label ?? '',
      };
    }
  }

  const teamsFilterIndex = copiedFilters.findIndex((filter) => filter.label === 'teams');
  if (teamsFilterIndex !== -1) {
    const teamsFilter = copiedFilters[teamsFilterIndex];

    if (teamsFilter?.value && Array.isArray(teamsFilter.value)) {
      const result = teamsOptions?.filter((item) => teamsFilter.value.includes(item.value));
      copiedFilters[teamsFilterIndex] = {
        label: 'teams',
        value: result?.map((item) => item.label) ?? '',
      };
    } else if (typeof teamsFilter?.value === 'string') {
      const result = teamsOptions?.find((item) => teamsFilter.value === item.value);
      copiedFilters[teamsFilterIndex] = {
        label: 'teams',
        value: result?.label ?? '',
      };
    }
  }

  const audienceFilterIndex = copiedFilters.findIndex((filter) => filter.label === 'audiences');
  if (audienceFilterIndex !== -1) {
    const audienceFilter = copiedFilters[audienceFilterIndex];

    if (audienceFilter?.value && Array.isArray(audienceFilter.value)) {
      const result = AUDIENCE_OPTIONS?.filter((item) => audienceFilter.value.includes(item.value));
      copiedFilters[audienceFilterIndex] = {
        label: 'audiences',
        value: result?.map((item) => item.label) ?? '',
      };
    } else if (typeof audienceFilter?.value === 'string') {
      const result = AUDIENCE_OPTIONS?.find((item) => audienceFilter.value === item.value);
      copiedFilters[audienceFilterIndex] = {
        label: 'audiences',
        value: result?.label ?? '',
      };
    }
  }

  const taskTagsFilterIndex = copiedFilters.findIndex((filter) => filter.label === 'taskTags');
  if (taskTagsFilterIndex !== -1) {
    const taskTagsFilter = copiedFilters[taskTagsFilterIndex];
    if (taskTagsFilter?.value && Array.isArray(taskTagsFilter.value)) {
      copiedFilters[taskTagsFilterIndex] = {
        label: 'taskTags',
        value: taskTagsFilter?.value.map((item) => transformTaskTag(item || '')) ?? '',
      };
    } else if (typeof taskTagsFilter?.value === 'string') {
      copiedFilters[taskTagsFilterIndex] = {
        label: 'taskTags',
        value: taskTagsFilter?.value ? transformTaskTag(taskTagsFilter?.value) : '',
      };
    }
  }
  const result = copiedFilters.filter(
    (item) =>
      ![
        'startCreateDate',
        'endCreateDate',
        'startDueDate',
        'endDueDate',
        'occurenceOrder',
        'triggerTime.occurenceOrder',
      ].includes(item.label),
  );

  return result;
};

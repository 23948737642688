import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { renderIcon } from './alert.settings';
import { AlertProps } from './alert.types';

const Alert: React.FC<AlertProps> = ({ children, type, handleClose, containerClasses }) => {
  const wrapperClasses = classNames(`py-4 px-4 rounded-lg flex items-center justify-between ${containerClasses}`, {
    'bg-blue-100': type === 'info',
    'bg-red-100': type === 'error',
    'bg-white shadow mb-4': type === 'warning',
  });

  return (
    <div data-testid="alert_info_banner" className={wrapperClasses}>
      <div className="flex items-center">
        {renderIcon(type)}
        {children}
      </div>
      {!!handleClose && (
        <span data-testid="close_alert_btn" onClick={handleClose} title="close button">
          <Common.Icon name="close" className="size-4 cursor-pointer text-black/30" />
        </span>
      )}
    </div>
  );
};

export default Alert;

import { CoreRow } from '@tanstack/react-table';
import classNames from 'classnames';

import { TasksTableColumnsProps, TaskStatus } from './tasksTable.types';
export const getThClasses = (columnId: string, sortField: string[]) => {
  return classNames(
    'min-w-[5rem] p-3.5 text-left',
    (sortField?.[0] === 'uniqueId' && columnId === '_id') ||
      sortField?.[0] === columnId.replace('_', '.') ||
      sortField?.[0].split('.')[0] === columnId.split('.')[0]?.split('[')[0]
      ? 'bg-gray-200'
      : 'bg-gray-100',
  );
};

export const getQueueRowStyles = (isClickable?: boolean, isExpanded?: boolean, isActive?: boolean) => {
  return classNames('h-14 text-sm', {
    'cursor-pointer': isClickable,
    'border-t border-t-gray-100': !isActive,
    'bg-orange-50': !isExpanded && !isActive,
    'bg-orange-100': isExpanded && !isActive,
    'bg-blue-50 border-y border-blue-200': isActive,
  });
};

export const getCellClasses = (width = 150) => {
  // default width for cell is equal to 150px
  const isStandardWidth = width === 150;
  // as I see, sometimes you receive NaN as width, for this purpose I've added || 200 as fallback
  const maxWidthAllowed = Math.min(width, 200) || 200;

  return classNames('p-3 font-medium text-sm break-words', {
    'max-w-[200px]': isStandardWidth,
    ['max-w-[' + maxWidthAllowed + 'px]']: !isStandardWidth,
  });
};

export const getRowStyles = (tableRow: CoreRow<TasksTableColumnsProps>, isRowActive?: boolean) => {
  const rowHeight = 'h-14';
  const activeRowStyle = 'bg-blue-50 border-y border-blue-200';
  const inactiveRowStyle = 'border-t border-gray-100';
  const textSize = 'text-sm';

  const { status, reminderInfo, pausedDetails, escalationDetails } = tableRow.original;

  if (status?.toLowerCase() === TaskStatus.Urgent) {
    return `${rowHeight} ${isRowActive ? activeRowStyle : inactiveRowStyle + ' bg-red bg-opacity-5'} ${textSize}`;
  }
  if (escalationDetails) {
    return `${rowHeight} ${isRowActive ? activeRowStyle : inactiveRowStyle + ' bg-yellow-50'} ${textSize}`;
  }
  if (reminderInfo || pausedDetails) {
    return `${rowHeight} ${isRowActive ? activeRowStyle : 'bg-blue-50'} ${textSize}`;
  }
  if (status?.toLowerCase() === TaskStatus.Total) {
    return `${rowHeight} ${isRowActive ? activeRowStyle : 'bg-secondary-100'} ${textSize}`;
  }
  return `${rowHeight} ${isRowActive ? activeRowStyle : inactiveRowStyle} ${textSize}`;
};

import React, { ReactElement, useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import queryString from 'query-string';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectBasicTables, setNewBasicTablesSorting } from 'store/basicTable/basicTableSlice';

import { ColumnProps, OrderTypes } from './header.types';

const Header: React.FC<ColumnProps> = ({
  text,
  sortField,
  tableName = undefined,
  noSort = undefined,
  customSortingTableName,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const basicTablesParams = useAppSelector(selectBasicTables);
  const basicTablesParamsSortField = customSortingTableName && basicTablesParams?.[customSortingTableName]?.sortField;
  const basicTablesParamsSortOrder = customSortingTableName && basicTablesParams?.[customSortingTableName]?.sortOrder;

  const [order, setOrder] = useState<OrderTypes>(searchParams.get('sortOrder') as OrderTypes);

  const handleClick = (value: OrderTypes) => {
    setOrder(value);

    if (customSortingTableName) {
      if (value) {
        dispatch(setNewBasicTablesSorting({ key: customSortingTableName, sortField, sortOrder: value }));
      } else {
        dispatch(setNewBasicTablesSorting({ key: customSortingTableName, sortField: null, sortOrder: null }));
      }
    } else {
      const queryParams = queryString.parse(location.search);
      let newQueryParams: Record<string, unknown>;

      if (tableName) {
        newQueryParams = {
          ...queryParams,
          ...(value && { [tableName + '--sf']: sortField }),
          ...(value && { [tableName + '--so']: value }),
        };
        if (!value) {
          delete newQueryParams[tableName + '--sf'];
          delete newQueryParams[tableName + '--so'];
        }
      } else
        newQueryParams = {
          ...queryParams,
          ...(value && { sortField: sortField }),
          ...(value && { sortOrder: value }),
        };
      if (!value) {
        delete newQueryParams.sortField;
        delete newQueryParams.sortOrder;
      }

      navigate({ search: queryString.stringify(newQueryParams) });
    }
  };

  const renderArrow = (value: OrderTypes) => {
    const queryParams = queryString.parse(location.search);
    const tableSortField = customSortingTableName ? basicTablesParamsSortField : queryParams?.sortField;

    if (tableName && !customSortingTableName) {
      queryParams.sortField = queryParams[tableName + '--sf'];
      value = queryParams[tableName + '--so'] ? (queryParams[tableName + '--so'] as OrderTypes) : null;
    }

    if (!value || (sortField && sortField !== tableSortField)) {
      return (
        <button data-testid="sort_btn" onClick={() => handleClick('ASC')} className="ml-2">
          <Common.Icon name="arrow-up" className="size-2 text-gray-400" />
          <Common.Icon name="arrow-down" className="size-2 text-gray-400" />
        </button>
      );
    }

    const arrows: { DESC: ReactElement; ASC: ReactElement } = {
      DESC: (
        <button data-testid="sort_btn" onClick={() => handleClick(null)} className="ml-2">
          <Common.Icon name="arrow-alt-up" className="size-3 text-primary" />
        </button>
      ),
      ASC: (
        <button data-testid="sort_btn" onClick={() => handleClick('DESC')} className="ml-2">
          <Common.Icon name="arrow-alt-down" className="size-3 text-primary" />
        </button>
      ),
    };

    if (sortField === tableSortField) {
      return arrows[value];
    }
  };

  useEffect(() => {
    if (!customSortingTableName && searchParams.has('sortOrder')) setOrder(searchParams.get('sortOrder') as OrderTypes);
  }, [searchParams, customSortingTableName]);

  useEffect(() => {
    if (customSortingTableName && basicTablesParamsSortField === sortField) {
      setOrder(basicTablesParamsSortOrder || 'DESC');
    }
  }, [basicTablesParamsSortField, basicTablesParamsSortOrder, customSortingTableName, sortField]);

  return (
    <div className="flex items-center text-sm font-bold">
      <span>{text}</span>
      {noSort ? <></> : renderArrow(order)}
    </div>
  );
};

export default Header;

import { useCallback, useMemo, useState } from 'react';

import { AutocompleteInputSelectProps } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { useDebounce } from 'react-use';

import { AvailableStaffForPatientProps, type AvailableStaffResponse } from 'store/tasks/task.types';
import { useLazyGetAvailableStaffForPatientQuery } from 'store/tasks/tasksSlice';

import { TaskCategory } from './createTaskForm.types';

export const assignToOptions = [
  { label: 'Myself', value: 'myself' },
  { label: 'Provider', value: 'PH' },
  { label: 'Medical assistant', value: 'MA' },
  { label: 'Customer service', value: 'CS' },
  { label: 'Specific staff member', value: 'SSM' },
];

export const useGetAvailableStaff = (
  params: AvailableStaffForPatientProps,
  includeLeadership?: boolean,
  isStaffMember?: boolean,
) => {
  const [availableStaff, setAvailableStaff] = useState<AvailableStaffResponse[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const { patientId, searchKey, isUrgentTask } = params;

  /**
   * We need to send a little different taskCategory value
   * than come in params.taskCategory
   */
  const taskCategory =
    params.taskCategory === TaskCategory.Request
      ? 'Requests'
      : params.taskCategory === TaskCategory.RequestPA
        ? 'Request • PA'
        : params.taskCategory;

  const [getAvailableStaff, { isFetching, isLoading }] = useLazyGetAvailableStaffForPatientQuery();

  useDebounce(
    () => {
      /**
       * The 'patientId' / 'taskCategory' fields are required for the request
       * The 'isStaffMember' field is responsible for whether “Specific staff member” is selected in the “Assign to field”
       * This is necessary to avoid sending a request, if we don't want to search for a specific staff
       */
      if (patientId && taskCategory && isStaffMember && searchKey?.trim()) {
        getAvailableStaff({ patientId: patientId, params: { ...params, taskCategory: taskCategory } }).then(
          ({ data }) => {
            data && setAvailableStaff(data.data);
            setIsSuccess(true);
          },
        );
      } else {
        setAvailableStaff([]);
        setIsSuccess(false);
      }
    },
    1000,
    [patientId, taskCategory, searchKey, isUrgentTask],
  );

  /**
   * This value show if the list has a clinical leader
   */
  const hasClinicalLeader = useMemo(() => {
    return availableStaff && searchKey?.trim() ? availableStaff.some((item) => item.isClinicalLeadership) : false;
  }, [availableStaff, searchKey]);

  const normalizeAvailableStaffData = useCallback(
    (data: AvailableStaffResponse[]): AutocompleteInputSelectProps['options'] =>
      data.flatMap((item) => {
        /**
         * If user don't want to include leadership in the list
         * And the item is a clinical leader
         * Then return an empty array
         */
        if (!includeLeadership && item.isClinicalLeadership) return [];

        return [
          {
            label: item.name,
            value: item,
            id: item._id,
            avatar: {
              size: 'sm',
              role: item.userType.shortCode,
              src: item.profileImage,
            },
            isClinicalLeadership: item.isClinicalLeadership,
            customClassName: !!item.warning?.message ? 'opacity-30' : '',
          },
        ];
      }),
    [includeLeadership],
  );

  const searchData: AutocompleteInputSelectProps['options'] = useMemo(
    () => (availableStaff?.length && searchKey?.trim() ? normalizeAvailableStaffData(availableStaff) : []),
    [availableStaff, normalizeAvailableStaffData],
  );

  return useMemo(
    () => ({
      searchData,
      isLoading: isLoading || isFetching,
      hasClinicalLeader,
      isSuccess,
    }),
    [searchData, isLoading, isFetching, hasClinicalLeader],
  );
};

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import { FRONTEND_DATE_FORMAT } from 'constants/dateFormat';
import { DateFormat } from 'enums/dateFormats';

export function getTimeDiff({
  startDate,
  endDate,
  unit,
}: {
  startDate?: string;
  endDate?: string;
  unit: dayjs.QUnitType | dayjs.OpUnitType;
}) {
  let result = null;
  const start = startDate ? dayjs(startDate) : null;
  const end = endDate ? dayjs(endDate) : null;

  if (start && end) {
    if (start.isValid() && end.isValid()) {
      result = end.diff(start, unit);
    } else {
      result = null;
    }
  }

  return result;
}

dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(relativeTime);

const timeZone = dayjs.tz.guess();

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a min',
    mm: '%d min',
    h: 'an hr',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export const dateTimeFormatter = (date: string | number, customCalendarFormat?: { [key: string]: string }) => {
  if (date)
    return dayjs
      .utc(date)
      .tz(timeZone)
      .calendar(
        null,
        customCalendarFormat || {
          sameDay: `h:mma z`,
          nextDay: '[Tomorrow •] h:mma z',
          nextWeek: 'MMM D • h:mma z',
          lastDay: '[Yesterday •] h:mma z',
          lastWeek: 'MMM D • h:mma z',
          sameElse: `MMM D • h:mma z`,
        },
      );
};

export const parseDateForTodayAndTomorrow = (date: string) => {
  if (dayjs(date).isToday()) {
    return 'Today';
  }
  if (dayjs(date).subtract(1, 'days').isToday()) {
    return 'Tomorrow';
  }
  return dayjs(date).format(DateFormat.MM_DD);
};

export const validateBirth = (val: string, message: string) => {
  const age = val ? dayjs().diff(dayjs(val).format(DateFormat.YYYY_MM_DD), 'year') : 0;
  const isValidAge = age >= 18 && age <= 62; // min and max age

  return (isValidAge && dayjs(val, FRONTEND_DATE_FORMAT).format(FRONTEND_DATE_FORMAT) === val) || message;
};

export const getTimezoneAbbreviation = (userTimezone: string) => {
  let result;
  try {
    result = Intl.DateTimeFormat('en-US', { timeZone: userTimezone, timeZoneName: 'short' })
      .format(new Date())
      .split(' ')?.[1];
  } catch (error) {
    result = 'Invalid timezone';
    console.error(error);
  }
  return result;
};

export const getRemainingTime = (date?: string) => {
  if (!date) return '';

  const days = dayjs(date).diff(dayjs(), 'days'); // Difference in whole days
  const hours = dayjs(date).diff(dayjs(), 'hours') % 24; // Remainder hours after full days

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }

  return hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
};

export const convertToUtcIsoString = (date: string, time: string) => {
  if (!date || !time) return;

  const hour = Number(time.split(':')[0]);
  const minute = Number(time.split(':')[1]);

  const dayjsDate = dayjs(date, 'MM/DD/YYYY').hour(hour).minute(minute);

  // Convert the dayjs instance to UTC and format it as an ISO string
  const utcIsoString = dayjsDate.utc().toISOString();

  return utcIsoString;
};

const statesAbbreviations: { [x: string]: string } = {
  al: 'Alabama',
  ak: 'Alaska',
  as: 'American_Samoa',
  az: 'Arizona',
  ar: 'Arkansas',
  ca: 'California',
  co: 'Colorado',
  ct: 'Connecticut',
  de: 'Delaware',
  dc: 'District of Columbia',
  fm: 'Federated States Of Micronesia',
  fl: 'Florida',
  ga: 'Georgia',
  gu: 'Guam',
  hi: 'Hawaii',
  id: 'Idaho',
  il: 'Illinois',
  in: 'Indiana',
  ia: 'Iowa',
  ks: 'Kansas',
  ky: 'Kentucky',
  la: 'Louisiana',
  me: 'Maine',
  mh: 'Marshall_Islands',
  md: 'Maryland',
  ma: 'Massachusetts',
  mi: 'Michigan',
  mn: 'Minnesota',
  ms: 'Mississippi',
  mo: 'Missouri',
  mt: 'Montana',
  ne: 'Nebraska',
  nv: 'Nevada',
  nh: 'New Hampshire',
  nj: 'New Jersey',
  nm: 'New Mexico',
  ny: 'New York',
  nc: 'North Carolina',
  nd: 'North Dakota',
  mp: 'Northern Mariana Islands',
  oh: 'Ohio',
  ok: 'Oklahoma',
  or: 'Oregon',
  pw: 'Palau',
  pa: 'Pennsylvania',
  pr: 'Puerto Rico',
  ri: 'Rhode Island',
  sc: 'South Carolina',
  sd: 'South Dakota',
  tn: 'Tennessee',
  tx: 'Texas',
  ut: 'Utah',
  vt: 'Vermont',
  vi: 'Virgin Islands',
  va: 'Virginia',
  wa: 'Washington',
  wv: 'West Virginia',
  wi: 'Wisconsin',
  wy: 'Wyoming',
};

const getStateAbbreviation = (stateName: string) => {
  const matchingAbbreviations = Object.keys(statesAbbreviations).filter(
    (abbreviation) => statesAbbreviations[abbreviation].toLowerCase() === stateName.toLowerCase(),
  );
  return matchingAbbreviations.length > 0 ? matchingAbbreviations[0] : null;
};

export const getStateTimezone = (stateName: string) => {
  const abbreviation = getStateAbbreviation(stateName);

  switch (abbreviation) {
    case 'al':
      return 'America/Chicago';
    case 'ak':
      return 'America/Anchorage';
    case 'az':
      return 'America/Phoenix';
    case 'ar':
      return 'America/Chicago';
    case 'ca':
      return 'America/Los_Angeles';
    case 'co':
      return 'America/Denver';
    case 'ct':
      return 'America/New_York';
    case 'de':
      return 'America/New_York';
    case 'fl':
      return 'America/New_York';
    case 'ga':
      return 'America/New_York';
    case 'hi':
      return 'Pacific/Honolulu';
    case 'id':
      return 'America/Boise';
    case 'il':
      return 'America/Chicago';
    case 'in':
      return 'America/Indiana/Indianapolis';
    case 'ia':
      return 'America/Chicago';
    case 'ks':
      return 'America/Chicago';
    case 'ky':
      return 'America/New_York';
    case 'la':
      return 'America/Chicago';
    case 'me':
      return 'America/New_York';
    case 'md':
      return 'America/New_York';
    case 'ma':
      return 'America/New_York';
    case 'mi':
      return 'America/New_York';
    case 'mn':
      return 'America/Chicago';
    case 'ms':
      return 'America/Chicago';
    case 'mo':
      return 'America/Chicago';
    case 'mt':
      return 'America/Denver';
    case 'ne':
      return 'America/Chicago';
    case 'nv':
      return 'America/Los_Angeles';
    case 'nh':
      return 'America/New_York';
    case 'nj':
      return 'America/New_York';
    case 'nm':
      return 'America/Denver';
    case 'ny':
      return 'America/New_York';
    case 'nc':
      return 'America/New_York';
    case 'nd':
      return 'America/Chicago';
    case 'oh':
      return 'America/New_York';
    case 'ok':
      return 'America/Chicago';
    case 'or':
      return 'America/Los_Angeles';
    case 'pa':
      return 'America/New_York';
    case 'ri':
      return 'America/New_York';
    case 'sc':
      return 'America/New_York';
    case 'sd':
      return 'America/Chicago';
    case 'tn':
      return 'America/Chicago';
    case 'tx':
      return 'America/Chicago';
    case 'ut':
      return 'America/Denver';
    case 'vt':
      return 'America/New_York';
    case 'va':
      return 'America/New_York';
    case 'wa':
      return 'America/Los_Angeles';
    case 'wv':
      return 'America/New_York';
    case 'wi':
      return 'America/Chicago';
    case 'wy':
      return 'America/Denver';
    default:
      return 'America/New_York';
  }
};

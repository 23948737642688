import { Common } from '@thecvlb/design-system/lib/src';
import { ProfileImageProps } from '@thecvlb/design-system/lib/src/common';

import TransferPopup from 'components/common/TransferPopup';
import { TransferPopupItemProps } from 'components/common/TransferPopup/transferPopup.types';
import User from 'components/common/User';
import { useAppSelector } from 'hooks/redux';
import type { AssignedToInfo } from 'models/tasks.types';
import { useUpdateTaskAssignMutation } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';

import { generateAudienceIcon, generateAudienceTitle, getAudience } from './dateCell.settings';

const AssignedToCell: React.FC<Partial<AssignedToInfo>> = ({
  _id,
  profileImage,
  userRoleInfo,
  name,
  specificAudience = [],
  defaultAudience = [],
  category,
  taskId,
  tags,
  escalationDetails,
}) => {
  const { _id: userId } = useAppSelector(selectUser);
  const [updateTaskAssign] = useUpdateTaskAssignMutation();
  const audience = getAudience(specificAudience, defaultAudience);

  const reassignTaskAudience = (item: TransferPopupItemProps) => {
    updateTaskAssign({ taskId: taskId ?? '', audience: [item.shortCode] });
  };

  const showAssignedToProvider = _id && name;
  const showEscalatedTo = !showAssignedToProvider && !!escalationDetails;
  const showAssignedAudience = !showAssignedToProvider && !showEscalatedTo && audience.length > 0;
  const showUnassigned = !showAssignedToProvider && !showEscalatedTo && !showAssignedAudience;

  const escalatedIcon = (
    <div
      title="Escalate to"
      className="flex size-5 min-w-[20px] items-center justify-center rounded-full bg-yellow-700"
    >
      <Common.Icon name="error" className="size-4 text-white" />
    </div>
  );

  const escalatedTo = !!escalationDetails?.teams?.length
    ? escalationDetails?.teams?.map((team) => team.name).join(', ')
    : escalationDetails?.user
      ? escalationDetails?.user.name
      : '';

  return (
    <div className="flex items-center font-normal">
      <TransferPopup
        src={profileImage}
        name={name ?? ''}
        role={userRoleInfo?.shortName}
        onButtonClick={reassignTaskAudience}
        isTasksList
        assignedToId={_id}
        taskId={taskId}
        tags={tags}
        taskCategory={category}
        specificAudience={audience}
      >
        {!!showAssignedToProvider && (
          <>
            {!!escalationDetails && escalatedIcon}
            <User
              src={profileImage}
              role={userRoleInfo?.shortName as ProfileImageProps['role']}
              name={_id === userId ? 'Me' : name}
            />
          </>
        )}
        {showEscalatedTo && (
          <div className="flex items-center justify-center gap-1">
            {escalatedIcon}
            <span className="text-sm font-semibold">{escalatedTo}</span>
          </div>
        )}
        {showAssignedAudience && (
          <div className="flex items-center">
            {generateAudienceIcon(audience)}
            <div data-testid="user_name" className="ml-1 text-sm font-medium text-gray-700">
              {generateAudienceTitle(audience)}
            </div>
          </div>
        )}
        {showUnassigned && <span className="text-sm font-normal">Unassigned</span>}
      </TransferPopup>
    </div>
  );
};

export default AssignedToCell;

import { DayHeaderContentArg, MoreLinkContentArg } from '@fullcalendar/core';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';

import { CalendarEvent } from './calendar.types';

const DayHeaderContent = (props: DayHeaderContentArg) => {
  const isWeekend = dayjs(props.date).day() === 0 || dayjs(props.date).day() === 6;

  const todayClasses = classNames('mr-1', {
    'text-gray-500': isWeekend,
  });

  const todayNumberClasses = classNames('font-bold', {
    'bg-secondary-600 text-white rounded-full p-1 size-6 inline-block': props.isToday,
    'text-gray-500': isWeekend,
  });

  return (
    <>
      <span className={todayClasses}>{dayjs(props.date).format(DateFormat.ddd)}</span>
      <span className={todayNumberClasses}>{dayjs(props.date).format(DateFormat.D)}</span>
    </>
  );
};

const MoreLinkContent = (data: MoreLinkContentArg) => {
  return <span className="rounded-lg bg-gray-800/5 px-1.5 py-px text-2xs font-bold text-gray-700">+{data.num}</span>;
};

const statusPriority = (status?: string): number => {
  switch (status) {
    case 'pending':
      return 1;
    case 'completed':
      return 2;
    default:
      return 3;
  }
};

const getStatusFromEvent = (event: unknown) => {
  if (typeof event === 'object' && event !== null) {
    return (event as CalendarEvent).status;
  }
  return '';
};

const eventOrder = (firstEvent: unknown, secondEvent: unknown) => {
  const statusA = statusPriority(getStatusFromEvent(firstEvent));
  const statusB = statusPriority(getStatusFromEvent(secondEvent));

  if (statusA < statusB) {
    return -1;
  } else if (statusA > statusB) {
    return 1;
  } else {
    return 0;
  }
};
export { DayHeaderContent, MoreLinkContent, eventOrder };

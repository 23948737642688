import { useCallback, useRef, useState } from 'react';

import { Transition } from '@headlessui/react';
import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { Editor } from '@tinymce/tinymce-react/node_modules/tinymce';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useClickAway } from 'react-use';

import ChatInputFile from 'components/common/Chat/ChatInputFile';
import FilePreview from 'components/common/Chat/FilePreview';
import { MessageEditor } from 'components/common/Chat/MessageEditor/MessageEditor';
import { handleEditorFocus } from 'components/common/Chat/MessageEditor/messageEditor.settings';
import Templates from 'components/patient/Channel/Form/Templates';
import { useTemplates } from 'contexts/TemplatesContext/TemplatesContext';
import { Role } from 'enums/role';
import { TaskCategories } from 'enums/taskCategories';
import { useAppSelector } from 'hooks/redux';
import { useLazyGetMessageQuery } from 'store/api/apiSlice';
import { useCreateStaffNoteMutation, useCreateTaskStaffNoteMutation } from 'store/staffNotes/staffNotesSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { parseMessage } from 'utils/common/messages/parseMessage';
import { sanitizeHTML, stripHTML } from 'utils/common/parseHTML';

import { StyledFormWrapper } from '../../Channel/Form/form.styled';

const selectStaffNotesFormState = createSelector([selectTask, selectUser], (task, user) => ({
  taskDetailsPatientId: task.taskDetails.personalInfo._id,
  taskId: task.taskDetails._id,
  category: task.taskDetails?.category,
  userRole: user.userType.name,
  doctorId: user._id,
}));

const StaffNotesForm = () => {
  const { category, userRole, doctorId, taskDetailsPatientId, taskId } = useAppSelector(selectStaffNotesFormState);
  const {
    showTemplates,
    setShowTemplates,
    setSearchQuery,
    templates,
    selectedTemplateIndex,
    setSelectedTemplateIndex,
  } = useTemplates();
  const { control, watch, setValue } = useForm({
    defaultValues: {
      message: '',
    },
  });
  const staffNoteText = watch('message', '');

  const { patientId = '' } = useParams<{ patientId: string }>();

  const [getMessageOfTemplate] = useLazyGetMessageQuery();

  const [sendStaffNote, { isLoading }] = useCreateStaffNoteMutation();
  const [sendTaskStaffNote, { isLoading: isSendingTaskStaffNote }] = useCreateTaskStaffNoteMutation({
    fixedCacheKey: 'shared-create-staff-note',
  });

  const showCreateTaskForMA =
    !!taskId && !(category.toLowerCase() === TaskCategories.FrontDesk.toLowerCase() && userRole === Role.AD);
  const isMAorCS = userRole === Role.MA || userRole === Role.CS;

  const [focus, setFocus] = useState(false);
  const [filePreviews, setFilePreviews] = useState<(string | ArrayBuffer | null)[]>([]);
  const [filesSelected, setFilesSelected] = useState<(File | null)[]>([]);
  const [isSentStaffNote, setIsSentStaffNote] = useState(false);

  const [checkedMarkAsUrgent, setCheckedMarkAsUrgent] = useState(false);
  const [checkedCreateTaskForMA, setCheckedCreateTaskForMA] = useState(showCreateTaskForMA && !isMAorCS);

  const id = taskDetailsPatientId || patientId;

  const disabledSendStaffNote = !stripHTML(staffNoteText).trim() || isSentStaffNote || isSendingTaskStaffNote;
  const disabledTemplates = showTemplates || staffNoteText.trim().length !== 0;

  const wrapperClasses = classNames('relative rounded-xl border border-gray-400', {
    'ring-blue-500 ring-2': focus,
  });

  const formRef = useRef<HTMLDivElement | null>(null);
  const templatesRef = useRef<HTMLDivElement | null>(null);

  useClickAway(formRef, () => {
    setFocus(false);
  });

  useClickAway(templatesRef, () => {
    setShowTemplates(false);

    if (stripHTML(staffNoteText) === '/') {
      setValue('message', '');
    }
  });

  const resetForm = () => {
    setFilePreviews([]);
    setFilesSelected([]);
    setCheckedMarkAsUrgent(false);
    setCheckedCreateTaskForMA(false);
    setValue('message', '');
  };

  const onSendMessage = async () => {
    setIsSentStaffNote(true);
    const sanitizedHTML = sanitizeHTML(staffNoteText, []);

    if ((!sanitizedHTML || sanitizedHTML.trim().length === 0) && !filesSelected.length) {
      return;
    }

    const body = {
      note: parseMessage(staffNoteText),
      staffNoteFile: filesSelected[0],
      isUrgent: checkedMarkAsUrgent,
      ...(checkedCreateTaskForMA && { audience: ['MA'] }),
    };

    if (taskId) {
      sendTaskStaffNote({ taskId, body })
        .unwrap()
        .then((res) => {
          if (res) resetForm();
          setIsSentStaffNote(false);
        })
        .catch((error) => {
          console.error(error);
          setIsSentStaffNote(false);
        });
    } else {
      sendStaffNote({ patientId: id, body })
        .unwrap()
        .then((res) => {
          if (res) resetForm();
          setIsSentStaffNote(false);
        })
        .catch((error) => {
          console.error(error);
          setIsSentStaffNote(false);
        });
    }
  };

  const onChangeFileHandler = (file: File | null, preview: string | ArrayBuffer | null) => {
    // currently StaffNotesForm supports uploading only one file
    // will be updated ufter backend changes
    setFilePreviews([preview]);
    setFilesSelected([file]);
  };

  const clearFile = () => {
    setFilePreviews([]);
    setFilesSelected([]);
  };

  const handleTemplatesClick = () => {
    setValue('message', '/');
    setShowTemplates(true);
  };

  const onTemplateListClick = useCallback(
    (_id: string, editor?: Editor) => {
      setShowTemplates(false);

      const params = {
        id: _id,
        type: 'Internal Note',
        patientId: id,
        doctorId: doctorId,
      };

      getMessageOfTemplate(params)
        .unwrap()
        .then((response) => {
          const content = response?.message || '';

          if (editor) {
            editor?.setContent(content);
            handleEditorFocus(editor);
          } else {
            setValue('message', content);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [doctorId, getMessageOfTemplate, id, setShowTemplates, setValue],
  );

  const handleKeyDown = async (event: KeyboardEvent, editor: Editor) => {
    const isUp = event.key === 'ArrowUp';
    const isDown = event.key === 'ArrowDown';
    const isEnter = event.key === 'Enter';

    if (isLoading || isSendingTaskStaffNote) return;

    if (isEnter && !event.shiftKey && !showTemplates) {
      event.preventDefault();
      await onSendMessage();
    } else if (isEnter && !event.shiftKey && templates[selectedTemplateIndex]) {
      // Handle template selection on Enter key press
      event.preventDefault();
      onTemplateListClick(templates[selectedTemplateIndex]._id, editor);
    }

    // Scroll up or down through templates
    if (isUp && showTemplates) {
      event.preventDefault();
      selectedTemplateIndex === 0
        ? setSelectedTemplateIndex(templates.length - 1)
        : setSelectedTemplateIndex(selectedTemplateIndex - 1);
    }

    if (isDown && showTemplates) {
      event.preventDefault();
      selectedTemplateIndex === templates.length - 1
        ? setSelectedTemplateIndex(0)
        : setSelectedTemplateIndex(selectedTemplateIndex + 1);
    }

    // Hide templates
    if (event.key === 'Escape') {
      setShowTemplates(false);
    }

    // Open templates
    if (event.key === '/' && !showTemplates) {
      setShowTemplates(true);
    }
  };

  return (
    <div className={wrapperClasses} ref={formRef}>
      {showTemplates && <Templates onTemplateListClick={onTemplateListClick} ref={templatesRef} />}

      <FilePreview previews={filePreviews} onClose={clearFile} />
      <StyledFormWrapper>
        <div className="relative flex max-h-[260px] min-h-[90px] flex-col">
          <Controller
            name="message"
            control={control}
            render={({ field: { onChange, ref } }) => {
              const handleEditorChange = (value: string, editor: Editor) => {
                const text = stripHTML(value);

                onChange(value);

                // Put cursor at the end of the user input
                if (text.trim() === '/') {
                  editor.selection.select(editor.getBody(), true);
                  editor.selection.collapse(false);
                  editor.focus();
                }

                if (text.startsWith('/')) {
                  setSearchQuery(text.replace('/', ''));
                } else if (!text.startsWith('/')) {
                  setShowTemplates(false);
                }
              };

              return (
                <MessageEditor
                  value={staffNoteText}
                  onEditorChange={handleEditorChange}
                  onInit={(_, editor) => ref(editor)}
                  onKeyDown={handleKeyDown}
                  placeholder="Add note to staff..."
                  onFocus={() => setFocus(true)}
                />
              );
            }}
          />

          <div className="absolute bottom-0 flex w-full justify-between rounded-b-xl bg-white p-1">
            <div className="flex items-center gap-1">
              <ChatInputFile onChange={onChangeFileHandler} filesSelected={filesSelected} className="text-gray" />
              <Common.Button
                dataTestId="reply_templates_btn"
                onClick={handleTemplatesClick}
                size="sm"
                preIcon="lightning"
                color="white-alt"
                disabled={disabledTemplates}
              >
                Templates
              </Common.Button>
            </div>
            <div className="flex items-center gap-2">
              <Transition
                show={checkedCreateTaskForMA}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Common.Checkbox
                  className="transition-opacity"
                  dataTestId={`mark_as_urgent_checkbox_${checkedMarkAsUrgent}`}
                  checked={checkedMarkAsUrgent}
                  onChange={() => setCheckedMarkAsUrgent(!checkedMarkAsUrgent)}
                  size="sm"
                >
                  Mark as urgent
                </Common.Checkbox>
              </Transition>
              {showCreateTaskForMA && (
                <Common.Checkbox
                  dataTestId={`create_task_for_ma_checkbox_${checkedCreateTaskForMA}`}
                  checked={checkedCreateTaskForMA}
                  onChange={() => setCheckedCreateTaskForMA(!checkedCreateTaskForMA)}
                  size="sm"
                >
                  Create task for MA
                </Common.Checkbox>
              )}

              <Common.Button
                dataTestId="send_msg_btn"
                onClick={onSendMessage}
                disabled={disabledSendStaffNote}
                size="sm"
                type="submit"
                color="green"
                className="rounded-lg px-3 py-1"
              >
                <Common.Icon name="send" className={'size-4'} />
              </Common.Button>
            </div>
          </div>
        </div>
      </StyledFormWrapper>
    </div>
  );
};

export default StaffNotesForm;

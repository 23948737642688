import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { FieldError, FormProvider, useController, useForm, useWatch } from 'react-hook-form';
import { useToggle } from 'react-use';

import Alert from 'components/common/Alert';
import DatePickerInput from 'components/common/DatePickerInput';
import SearchCombobox from 'components/common/form/SearchCombobox/SearchCombobox';
import TimeInput from 'components/common/form/timeInput';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { DateFormat } from 'enums/dateFormats';
import { MedicationBrandNames } from 'enums/medications';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { useCreateTaskStaffNoteMutation } from 'store/staffNotes/staffNotesSlice';
import { AvailableStaffResponse, CreateNewTaskParams, RequestPAProps } from 'store/tasks/task.types';
import { selectTask, useCreateNewTaskMutation, useRequestPAMutation } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { validation } from 'utils/helpers';

import { assignToOptions, useGetAvailableStaff } from './createTaskForm.settings';
import { CreateTaskFormProps, CreateTaskFormState, TaskCategory } from './createTaskForm.types';
import ControlledCombobox from '../../forms/controlled/ControlledCombobox';
import { handleRequired } from '../AppointmentTypes/appointmentType.settings';

const taskCategoryOptions = [
  { label: TaskCategory.Request, value: TaskCategory.Request },
  { label: TaskCategory.RequestPA, value: TaskCategory.RequestPA },
];

dayjs.extend(LocalizedFormat);

const selectCreateTaskFormState = createSelector([selectTask, selectUser], (task, user) => ({
  taskDetails: task.taskDetails,
  userId: user._id,
}));

const CreateTaskForm = ({ patient }: CreateTaskFormProps) => {
  const dispatch = useAppDispatch();
  const { taskDetails, userId } = useAppSelector(selectCreateTaskFormState);
  const [requestPA, { isLoading: isLoadingRequestPA }] = useRequestPAMutation();
  const [createNewTask, { isLoading }] = useCreateNewTaskMutation();
  const [sendTaskStaffNote] = useCreateTaskStaffNoteMutation();
  const methods = useForm<CreateTaskFormState>();
  const { handleSubmit, control, watch, setValue, getValues, register, formState, clearErrors, setError } = methods;
  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name: 'assignTo',
    defaultValue: assignToOptions[2].value, // default value is 'Medical assistant'
    rules: {
      required: 'Select assign to option',
    },
  });

  const isStaffMember = watch('assignTo') === 'SSM';
  const formData = useWatch({ control }) as CreateTaskFormState;
  const isRequestPA = formData.taskCategory?.value === TaskCategory.RequestPA;
  const disableCreateTask = isLoading || isLoadingRequestPA;

  const [isDueDate, setIsDueDate] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);
  const [staffSearchKey, setStaffSearchKey] = useState('');
  const [includeLeadership, toggleIncludeLeadership] = useToggle(false);
  const [isTaskNotUrgent, toggleIsTaskNotUrgent] = useToggle(false);
  const [dueDate, setDueDate] = useState(dayjs().format(DateFormat.MM_DD_YYYY));

  const selectedTaskCategory = getValues('taskCategory');
  const selectedPatient = getValues('patient');
  const disabledStaffField = !selectedPatient?.id || !selectedTaskCategory?.value;

  const staffError = (formData?.staff?.value as AvailableStaffResponse)?.warning
    ? (formData?.staff?.value as AvailableStaffResponse)?.warning
    : undefined;

  const {
    searchData,
    isLoading: isLoadingStaff,
    hasClinicalLeader,
    isSuccess,
  } = useGetAvailableStaff(
    {
      taskCategory: selectedTaskCategory?.value || '',
      patientId: selectedPatient?.id || patient?.id,
      searchKey: staffSearchKey,
      isUrgentTask: isUrgent,
      timezone: dayjs.tz.guess(),
    },
    includeLeadership,
    isStaffMember,
  );

  const noAvailableStaffMembers =
    isSuccess && !isLoading && !!staffSearchKey && !searchData.length
      ? { warning: 'No staff members found' }
      : undefined;

  const patientInfo = useMemo(() => {
    const label = taskDetails?.personalInfo?.firstName
      ? `${taskDetails.personalInfo.firstName} ${taskDetails.personalInfo.lastName}`
      : patient
        ? patient.fullName
        : '';

    const value = taskDetails?.personalInfo?._id || patient?.id || '';
    const id = value;

    return {
      label,
      value,
      id,
    };
  }, [patient, taskDetails.personalInfo]);

  const hasDefaultPatient = Object.values(patientInfo).every((value) => Boolean(value));

  const medicationOptions = Object.values(MedicationBrandNames).map((medication) => ({
    value: medication,
    label: medication,
  }));

  const handleClearStaff = (clearStaffField?: boolean) => {
    if (clearStaffField) setStaffSearchKey('');
    clearErrors('staff');
    setValue('staff', {
      id: '',
      value: '',
      label: '',
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStaffSearchKey(event?.target.value);
    if (!event.target.value?.length) {
      handleClearStaff(true);
    }
  };

  const handleChangeStaff = (option: Option) => {
    clearErrors('staff');
    setValue('staff', option);

    const warningMessage = (option.value as AvailableStaffResponse)?.warning?.message;

    if (warningMessage) {
      setError(`staff`, { type: 'manual', message: warningMessage });
    }
    toggleIsTaskNotUrgent(false);
  };

  const handleChangeIsUrgentStatus = (checked: boolean) => {
    setIsUrgent(checked);
    toggleIsTaskNotUrgent(false);
    setValue('isUrgent', checked);

    /**
     * If the selected staff has an error we remove it from the form (Requirement of the task)
lo     */
    if (staffError?.message) {
      handleClearStaff(true);
      setError('staff', {
        type: 'manual',
        message: 'Please choose a different provider who is currently available in order create an urgent task.',
      });
    }
  };

  const handleCancel = () => dispatch(closeModal());

  const onSubmit = (data: CreateTaskFormState) => {
    const reqPAData: RequestPAProps = {
      patientUserId: data.patient.id,
      isUrgent: data.isUrgent,
      note: data.medication?.value || '',
      medication: data.medication?.value,
      timezone: dayjs.tz.guess(),
    };
    const reqData: CreateNewTaskParams = {
      patientId: data.patient.id,
      note: data.taskDetails,
      ...(isDueDate && { dueDate: dayjs(data.dueDate.date + ' ' + data.dueDate.time).toISOString() }),
      isUrgent: data.isUrgent,
      ...(Boolean(taskDetails._id) && { parentTaskId: taskDetails._id }),
      timezone: dayjs.tz.guess(),
    };

    if (data?.assignTo?.toLowerCase() === 'myself') {
      (isRequestPA ? reqPAData : reqData).assignedTo = userId;
    } else if (data?.assignTo?.toLowerCase() === 'ssm' && data?.staff?.id) {
      (isRequestPA ? reqPAData : reqData).assignedTo = data.staff.id;
    } else {
      (isRequestPA ? reqPAData : reqData).audience = data.assignTo;
    }

    (isRequestPA ? requestPA({ ...reqPAData }) : createNewTask({ body: reqData }))
      .unwrap()
      .then((res) => {
        // Send staff note if it is a request task
        if (!isRequestPA && data.taskDetails && res?.data?.id) {
          sendTaskStaffNote({
            taskId: res.data.id,
            body: { note: data.taskDetails, isUrgent: data.isUrgent, staffNoteFile: null },
          });
        }

        notifySuccess('Task has been successfully created');
      })
      .finally(() => {
        dispatch(closeModal());
      });
  };

  useEffect(() => {
    setValue('taskCategory', taskCategoryOptions[0]);
  }, [setValue]);

  useEffect(() => {
    setValue('dueDate', {
      time: getValues('dueDate.time'),
      date: dayjs(dueDate.toString(), DateFormat.MM_DD_YYYY).format(DateFormat.MM_DD_YYYY),
    });
  }, [dueDate, setValue, getValues]);

  useEffect(() => {
    handleClearStaff(true);
  }, [selectedTaskCategory, selectedPatient]);

  return (
    <>
      <Loader isVisible={isLoading} />
      <div data-testid="create_task_form" className="p-6">
        <h2 data-testid="header" className="mb-6 text-xl font-bold text-gray-700">
          Create new task
        </h2>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <ControlledSelect
              control={control}
              options={taskCategoryOptions}
              name="taskCategory"
              className="w-full"
              label="Task category"
              rules={validation('Task category')}
              errors={formState.errors.taskCategory}
            />

            <SearchCombobox
              control={control}
              setValue={setValue}
              name="patient"
              label="For"
              userType="Patient"
              placeholder="Search for a patient"
              defaultValue={hasDefaultPatient ? patientInfo : ''}
              isRequired
              size="sm"
              preIcon="search"
              labelDirection="col"
            />
            <Common.SelectAlt
              dataTestId="assign_to_dropdown"
              label="Assign to"
              size="sm"
              name={field.name}
              value={field.value || ''}
              options={assignToOptions}
              onChange={(value) => {
                field.onChange(value);
              }}
              hideSuccessState
              error={errors[field.name] as FieldError}
            />

            {isStaffMember && (
              <>
                {hasClinicalLeader && (
                  <div>
                    <Common.Checkbox checked={includeLeadership} onChange={toggleIncludeLeadership} color="blue">
                      Include clinical leadership - please only do this when other providers are unavailable
                    </Common.Checkbox>
                  </div>
                )}
                <ControlledCombobox
                  size="sm"
                  control={control}
                  name="staff"
                  label="Staff member"
                  placeholder="Search for staff"
                  labelDirection="col"
                  preIcon="search"
                  inputValue={staffSearchKey}
                  onChange={handleChangeStaff}
                  onInputChange={handleInputChange}
                  options={searchData}
                  isLoading={isLoadingStaff}
                  disabled={disabledStaffField}
                  hideComboboxButton
                  errors={staffError || noAvailableStaffMembers}
                  helperText={staffError?.message || noAvailableStaffMembers?.warning}
                  immediate
                  showAllOptions
                  rules={{
                    validate: {
                      required: (value) => handleRequired(value, 'Please select a staff member'),
                    },
                  }}
                />
                {disabledStaffField && (
                  <div className="-mt-3 text-sm text-gray-500">Please choose a patient to assign a staff member.</div>
                )}
                {staffError?.shouldConfirm && (
                  <div>
                    <Common.Checkbox checked={isTaskNotUrgent} onChange={toggleIsTaskNotUrgent} color="blue">
                      I confirm that the task is not urgent so it can wait more than 48 hours
                    </Common.Checkbox>
                  </div>
                )}
              </>
            )}
            {isRequestPA && (
              <ControlledSelect
                control={control}
                labelDirection="col"
                options={medicationOptions}
                rules={validation('Medication')}
                placeholder="Select..."
                label="Medication"
                name="medication"
              />
            )}
            {!isRequestPA && (
              <Common.TextArea
                dataTestId="task_details_field"
                label="Task details"
                size="sm"
                placeholder="What do you need completed?"
                {...register('taskDetails', {
                  required: { value: true, message: 'Please fill out this field' },
                })}
                helper={formState.errors.taskDetails ? 'Please fill out this field' : ''}
                errors={formState.errors.taskDetails}
              />
            )}
            {!isRequestPA && (
              <Common.Checkbox
                dataTestId="add_due_date_checkbox"
                value="dueDate"
                size="sm"
                color="blue"
                onChange={(event) => {
                  setIsDueDate(event.target.checked);
                }}
                checked={isDueDate}
              >
                Add a due date
              </Common.Checkbox>
            )}
            {isDueDate && (
              <div className="flex items-center gap-4">
                <DatePickerInput
                  dataTestId="due_date_scope"
                  wrapperClasses="w-11/12"
                  label="Due"
                  inputValue={dueDate}
                  setInputValue={(value) => setDueDate(value)}
                  setSelectedDate={() => {}}
                  inputClassName="!text-gray-500"
                  size="sm"
                />
                <TimeInput className="h-[33px] w-10/12 rounded-md" />
              </div>
            )}
            <Common.Checkbox
              dataTestId="mark_as_urgent_checkbox"
              size="sm"
              value="isUrgent"
              color="blue"
              onChange={(event) => handleChangeIsUrgentStatus(event.target.checked)}
              checked={isUrgent}
            >
              Mark as urgent
            </Common.Checkbox>
            {isUrgent && (
              <Alert type="error">
                <span className="text-base">
                  <span className="font-bold">Important: </span>Please only mark a task as urgent if it requires urgent
                  clinical attention.
                </span>
              </Alert>
            )}
            <div className="flex gap-2">
              <button
                data-testid="cancel_btn"
                className="w-full rounded-lg bg-gray-100 py-[7.5px] text-sm font-bold text-gray-700 disabled:bg-gray-200 disabled:text-gray"
                disabled={isLoading}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                data-testid="create_task_btn"
                className="w-full rounded-lg bg-primary py-[7.5px] text-sm font-bold text-white disabled:bg-gray-200 disabled:text-gray"
                disabled={disableCreateTask || (!!staffError?.shouldConfirm && !isTaskNotUrgent)}
              >
                Create task
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default CreateTaskForm;

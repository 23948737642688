import classNames from 'classnames';
import isString from 'lodash/isString';
import { useMatch } from 'react-router-dom';

import { PathName } from 'enums/pathName';
import { useGetPatientItems } from 'hooks/components/PatientInfo/useGetPatientItems';

import { PatientInfoProps } from './patientInfo.types';

const PatientInfo: React.FC<PatientInfoProps> = ({
  patientId,
  wrapperClassNames,
  labelClassNames,
  isTaskDetails,
  isSlidingPanel,
}) => {
  const isPatient = !!useMatch(`${PathName.Patient}/:id`);

  const wrapperClasses = classNames(wrapperClassNames || 'grid grid-cols-2 gap-2 gap-x-8');
  const labelClasses = classNames('text-base mr-1 min-w-fit flex-none text-gray', labelClassNames || 'w-24');
  const redDotClasses = classNames(
    'absolute -left-2  h-1 w-1 rounded-xl bg-red-500',
    isTaskDetails ? 'top-2' : 'top-4',
  );

  const { items } = useGetPatientItems({
    patientId,
    isSlidingPanel,
    isTaskDetails,
  });

  return (
    <div className={wrapperClasses}>
      {items?.map((el) => (
        <div data-testid="personal_info_data_field" key={el.label} className="relative flex items-baseline">
          {(isPatient || (!isPatient && el.label !== 'Status')) && (
            <>
              {el.showBadge && <span className={redDotClasses} />}
              <p className={labelClasses}>{el.label}</p>
              {isString(el.value) ? (
                <p
                  className={classNames('text-base text-gray-700', {
                    underline: el.isUnderline,
                  })}
                >
                  {el.value}
                </p>
              ) : (
                el.value
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default PatientInfo;

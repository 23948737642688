import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { closeModal } from 'store/modal/modalSlice';

import { PopupFooterProps } from './popupFooter.types';

const PopupFooter: React.FC<PopupFooterProps> = ({
  onRemove,
  onSave,
  hiddenDeleteButton,
  disabled,
  deleteButtonText,
  hideDeleteIcon,
  disabledDeleteButton,
  saveButtonText,
}) => {
  const dispatch = useDispatch();

  const wrapperClasses = classNames('flex content-end', hiddenDeleteButton ? 'justify-end' : 'justify-between');

  return (
    <div className={wrapperClasses}>
      {!hiddenDeleteButton && (
        <Common.Button
          dataTestId="delete_btn"
          onClick={onRemove}
          disabled={disabledDeleteButton ?? disabled}
          type="button"
          color="red-alt"
          size="sm"
          {...(!hideDeleteIcon && { preIcon: 'trash' })}
        >
          {deleteButtonText || 'Delete'}
        </Common.Button>
      )}
      <div className="flex gap-2">
        <Common.Button
          dataTestId="cancel_btn"
          type="button"
          color="white-alt"
          onClick={() => dispatch(closeModal())}
          size="sm"
        >
          Cancel
        </Common.Button>
        <Common.Button dataTestId="save_btn" onClick={onSave} type="submit" disabled={disabled} color="blue" size="sm">
          {saveButtonText || 'Save'}
        </Common.Button>
      </div>
    </div>
  );
};

export default PopupFooter;

import { useCallback, useRef, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { Editor } from '@tinymce/tinymce-react/node_modules/tinymce';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useClickAway } from 'react-use';

import Suggestions from 'components/chat/Suggestion';
import { getLatestPatientQuestions } from 'components/chat/Suggestion/suggestions.settings';
import ChatInputFile from 'components/common/Chat/ChatInputFile';
import FilePreview from 'components/common/Chat/FilePreview';
import { MessageEditor } from 'components/common/Chat/MessageEditor/MessageEditor';
import { handleEditorFocus } from 'components/common/Chat/MessageEditor/messageEditor.settings';
import Loader from 'components/common/Loader';
import Templates from 'components/patient/Channel/Form/Templates';
import { EDITOR_TOOLBAR_OPTIONS } from 'constants/editor';
import { ButtonProps } from 'contexts/MessagesContext/messagesContext.types';
import { useTemplates } from 'contexts/TemplatesContext/TemplatesContext';
import { TaskCategories } from 'enums/taskCategories';
import { useClientMessages } from 'hooks/common/useClientMessages';
import useEditorToolbar from 'hooks/components/MessagesEditor/useEditorToolbar';
import { useAppSelector } from 'hooks/redux';
import { UserRole } from 'models/roles.types';
import { selectAiAssistant, useSendAnalyticsMutation } from 'store/aiAssistant/aiAssistantSlice';
import { useLazyGetMessageQuery } from 'store/api/apiSlice';
import { FileItemProps } from 'store/chat/chat.types';
import { selectMessages, useUploadFileMutation } from 'store/chat/chatSlice';
import { checkIsPatientWMorPrevWM } from 'store/patients/patients.settings';
import { selectPatient } from 'store/patients/patientsSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { parseMessage } from 'utils/common/messages/parseMessage';
import { stripHTML } from 'utils/common/parseHTML';

import { StyledFormWrapper } from './form.styled';
import { FormProps } from './form.types';

const selectFormState = createSelector(
  [selectMessages, selectUser, selectPatient, selectTask, selectAiAssistant],
  (messages, user, patient, task, aiAssistant) => ({
    messages,
    userType: user.userType,
    _id: user._id,
    patientTags: patient?.patientInfo?.tags,
    patientTagsFromTask: task?.taskDetails?.patientInfo?.tags,
    suggestedResponse: aiAssistant.messageSuggestion,
    hashKey: aiAssistant.hashKey,
  }),
);

const Form: React.FC<FormProps> = ({ patientId = '', channelId, taskId, type, taskCategory }) => {
  const [sendAnalytics] = useSendAnalyticsMutation();

  const {
    setSearchQuery,
    showTemplates,
    setShowTemplates,
    templates,
    selectedTemplateIndex,
    setSelectedTemplateIndex,
  } = useTemplates();

  const methods = useForm({
    defaultValues: {
      message: '',
    },
  });
  const { control, watch, setValue } = methods;

  const {
    _id: doctorId,
    userType,
    messages,
    patientTags,
    patientTagsFromTask,
    suggestedResponse,
    hashKey,
  } = useAppSelector(selectFormState);
  const useMessages = useClientMessages({ type });
  const { sendMessageToServer } = useMessages();

  const [filePreviews, setFilePreviews] = useState<(string | ArrayBuffer | null)[]>([]);
  const [filesSelected, setFilesSelected] = useState<(File | null)[]>([]);
  const [messageButton, setMessageButton] = useState<ButtonProps[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [focus, setFocus] = useState(false);

  const { showToolbar, toggleFormatText } = useEditorToolbar();

  const messageText = watch('message', '');

  const [getMessageOfTemplate] = useLazyGetMessageQuery();

  const [uploadFile] = useUploadFileMutation();

  const disabledSubmit = (!stripHTML(messageText).trim() && !filesSelected.length) || disabled || !channelId;
  const disableTemplates = showTemplates || messageText.length > 0;

  const inputWrapperRef = useRef<HTMLDivElement | null>(null);
  const templatesWrapperRef = useRef<HTMLDivElement | null>(null);

  const wrapperClasses = classNames('relative flex flex-col rounded-xl border shadow-sm', {
    'ring-blue-500 ring-2': focus,
  });

  const isAIassistantDisabledForPH = useFlag('disable-response-ai-for-PH');
  const isAIassistantDisabledForMA = useFlag('disable-response-ai-for-MA');
  const isAIassistantDisabledForCS = useFlag('disable-response-ai-for-CS');
  const isFormattingEnabled = !useFlag('disable-message-formatting');

  const checkAIasistantEnabled = useCallback(
    (userTypeCode: UserRole) => {
      if (userTypeCode === 'PH') {
        return !isAIassistantDisabledForPH;
      } else if (userTypeCode === 'MA') {
        return !isAIassistantDisabledForMA;
      } else if (userTypeCode === 'CS') {
        return !isAIassistantDisabledForCS;
      }
      return false;
    },
    [isAIassistantDisabledForCS, isAIassistantDisabledForMA, isAIassistantDisabledForPH],
  );

  const onChangeFileHandler = (file: File | null, preview: string | ArrayBuffer | null) => {
    setFilePreviews((prev) => [...prev, preview]);
    setFilesSelected((prev) => [...prev, file]);
  };

  const clearFile = (index: number) => {
    setFilePreviews((prev) => prev.filter((_, idx) => idx !== index));
    setFilesSelected((prev) => prev.filter((_, idx) => idx !== index));
  };

  const clearFiles = () => {
    setFilePreviews([]);
    setFilesSelected([]);
  };

  const reset = () => {
    setValue('message', '');
    setMessageButton([]);
    clearFiles();
  };

  const uploadFileForChat = (file: File): Promise<FileItemProps | void> => {
    return uploadFile(file).unwrap();
  };

  const sendEditedResponseAIanalytics = (message: string) => {
    if (!hashKey || !message || !suggestedResponse) {
      return;
    }

    const isSuggestionEdited = suggestedResponse !== message;

    if (isSuggestionEdited) {
      sendAnalytics({ event: 'isEditResponse', key: hashKey });
    }
  };

  const sendMessage = async (message: string, file: FileItemProps | null, buttons: ButtonProps[]) => {
    const params = {
      message: parseMessage(message),
      channelId: channelId ?? '',
      buttons,
      receiverDetails: [{ id: patientId }],
      senderDetails: { id: doctorId, userType },
      ...(taskId && { taskId }),
      ...file,
    };

    sendMessageToServer({ newMessage: params, isAppointmentChat: false });
  };

  const onFormSubmit = async (message: string, buttons: ButtonProps[]) => {
    if (!stripHTML(message).trim().length && !filesSelected.length) {
      return;
    }

    if (filesSelected.length) {
      for (const [index, fileSelected] of filesSelected.entries()) {
        setDisabled(true);
        setShowLoader(true);

        const file = fileSelected && (await uploadFileForChat(fileSelected));

        setDisabled(false);
        setShowLoader(false);

        if (!file) {
          continue;
        }

        sendMessage(filesSelected.length - 1 === index ? parseMessage(message) : '', file, buttons);
      }
    } else {
      sendMessage(message, null, buttons);
    }

    sendEditedResponseAIanalytics(message);

    reset();
  };

  const onTemplateListClick = useCallback(
    (id: string, editor?: Editor) => {
      setShowTemplates(false);

      const params = {
        id,
        type: 'Message',
        patientId,
        doctorId,
      };

      getMessageOfTemplate(params)
        .unwrap()
        .then((response) => {
          if (response?.message) {
            const content = response?.message || '';

            if (editor) {
              editor?.setContent(content);
              handleEditorFocus(editor);
            } else {
              setValue('message', content);
            }
          }
          if (response?.buttons) setMessageButton(response?.buttons || []);
        })
        .catch((error) => console.error(error));
    },
    [doctorId, getMessageOfTemplate, patientId, setShowTemplates, setValue],
  );

  const handleKeyDown = (event: KeyboardEvent, editor: Editor) => {
    const isUp = event.key === 'ArrowUp';
    const isDown = event.key === 'ArrowDown';
    const isEnter = event.key === 'Enter';

    // NOTE: to have numbered list and bulled list on Enter key press working,
    // we should prevent default behavior of Enter key press
    const shouldReactForEnterKeyPressed =
      isEnter && !event.shiftKey && showTemplates && templates[selectedTemplateIndex];

    if (shouldReactForEnterKeyPressed) {
      // Handle template selection on Enter key press
      event.preventDefault();
      onTemplateListClick(templates[selectedTemplateIndex]._id, editor);
    } else {
      // Handle message submission on Enter key press
      if (isEnter && !event.shiftKey && !event.ctrlKey && !event.metaKey && !event.altKey) {
        event.preventDefault();
        onFormSubmit(messageText.trim(), messageButton);
      }
    }

    // Hide templates
    if (event.key === 'Escape') {
      setShowTemplates(false);
    }

    // Open templates
    if (event.key === '/' && !showTemplates) {
      setShowTemplates(true);
    }

    // Scroll up or down through templates
    if (isUp && showTemplates) {
      event.preventDefault();
      selectedTemplateIndex === 0
        ? setSelectedTemplateIndex(templates.length - 1)
        : setSelectedTemplateIndex(selectedTemplateIndex - 1);
    }

    if (isDown && showTemplates) {
      event.preventDefault();
      selectedTemplateIndex === templates.length - 1
        ? setSelectedTemplateIndex(0)
        : setSelectedTemplateIndex(selectedTemplateIndex + 1);
    }
  };

  const handleTemplatesClick = () => {
    setShowTemplates(true);
    setValue('message', '/');
  };

  useClickAway(inputWrapperRef, () => {
    setFocus(false);
  });

  useClickAway(templatesWrapperRef, () => {
    setShowTemplates(false);

    if (stripHTML(messageText) === '/') {
      setValue('message', '');
    }
  });

  const questions = getLatestPatientQuestions(messages);
  const isAIassistantEnabled = !!userType?.shortCode && checkAIasistantEnabled(userType.shortCode as UserRole);

  const isWMPatient = checkIsPatientWMorPrevWM(patientTags) || checkIsPatientWMorPrevWM(patientTagsFromTask);

  const showAIassistant = isAIassistantEnabled && questions && isWMPatient;

  const isPH = userType?.shortCode === 'PH';

  const isMessageTask = taskCategory === TaskCategories.Messages;

  return (
    <FormProvider {...methods}>
      <div className={wrapperClasses} ref={inputWrapperRef}>
        <Loader isVisible={showLoader} />
        {showTemplates && <Templates onTemplateListClick={onTemplateListClick} ref={templatesWrapperRef} />}
        <FilePreview previews={filePreviews} onClose={clearFile} />
        <StyledFormWrapper>
          <div className="relative flex max-h-[260px] min-h-[90px] flex-col">
            <Controller
              control={control}
              name="message"
              render={({ field: { onChange, ref } }) => {
                const handleEditorChange = (value: string, editor: Editor) => {
                  const text = stripHTML(value);

                  onChange(value);

                  // Put cursor at the end of the user input
                  if (text.trim() === '/') {
                    handleEditorFocus(editor);
                  }

                  if (text.startsWith('/')) {
                    setSearchQuery(text.replace('/', ''));
                  } else if (!text.startsWith('/')) {
                    setShowTemplates(false);
                  }
                };

                return (
                  <MessageEditor
                    onEditorChange={handleEditorChange}
                    value={messageText}
                    onInit={(_, editor) => ref(editor)}
                    onKeyDown={handleKeyDown}
                    placeholder="Message patient..."
                    onFocus={() => setFocus(true)}
                    toolbarOptions={EDITOR_TOOLBAR_OPTIONS}
                  />
                );
              }}
            />

            <div className="absolute bottom-0 w-full rounded-b-xl bg-white p-1">
              <div className="relative flex justify-between">
                <div className="flex items-center gap-1">
                  <ChatInputFile
                    onChange={onChangeFileHandler}
                    className="text-gray"
                    filesSelected={filesSelected}
                    multiple
                  />
                  <Common.Button
                    dataTestId="reply_templates_btn"
                    data-testid="reply_templates_button"
                    onClick={handleTemplatesClick}
                    preIcon="lightning"
                    size="sm"
                    color="white-alt"
                    disabled={disableTemplates}
                  >
                    Templates
                  </Common.Button>

                  {isFormattingEnabled && (
                    <Common.Button
                      dataTestId="format_text_btn"
                      data-testid="format_text_button"
                      onClick={toggleFormatText}
                      color="white-alt"
                      className={`${showToolbar ? 'bg-white ring-1 ring-gray-200' : ''} items-end rounded-lg`}
                      size="sm"
                    >
                      <Common.Icon name="format-text" className="size-4" />
                    </Common.Button>
                  )}
                </div>

                <Common.Button
                  dataTestId="send_msg_btn"
                  data-testid="send_msg_button"
                  onClick={() => onFormSubmit(messageText.trim(), messageButton)}
                  disabled={disabledSubmit}
                  type="submit"
                  color="blue"
                  className="items-end rounded-lg"
                  size="sm"
                >
                  <Common.Icon name="send" className="size-4" />
                </Common.Button>
              </div>
            </div>
          </div>
        </StyledFormWrapper>
      </div>

      {showAIassistant && (
        <Suggestions
          patientId={patientId}
          question={questions}
          isPH={isPH}
          isMessageTask={isMessageTask}
          isMessageInputFocused={focus}
        />
      )}
    </FormProvider>
  );
};

export default Form;

import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';

import { MembershipData } from 'store/appointments/appointments.types';

const PatientInsuranceBanner: React.FC<{
  insuranceEligibility: MembershipData['insuranceEligibility'];
}> = ({ insuranceEligibility }) => {
  const coinsurance = insuranceEligibility?.coinsurance || 0;

  const isPercentageDiscount = coinsurance > 0;
  const fixedFinalPrice = insuranceEligibility?.finalCost
    ? `${(insuranceEligibility.finalCost / 100).toFixed((insuranceEligibility.finalCost / 100) % 1 !== 0 ? 2 : 0)}`
    : 'N/A';

  return (
    <div className="mb-5 flex flex-col rounded-2xl border border-gray-200 bg-gray-100 p-4">
      <div className="relative mb-2 flex">
        <div className="flex-1 text-sm font-bold text-gray-700">Patient insurance has been accepted</div>
        <Common.Icon name="check-circle" className="absolute right-[-5px] top-[-5px] size-8 text-green-500" />
      </div>

      <div className="flex">
        <Common.Icon name="check-shield" className="size-5" />
        <div className="flex-col pl-1.5">
          <div className="text-sm font-medium text-gray-700">
            Patient total {isPercentageDiscount ? 'coinsurance' : 'copay'} is{' '}
            {isPercentageDiscount ? `${coinsurance}%` : `$${fixedFinalPrice}`} for this telehealth appointment.
          </div>
          <div className="text-sm font-medium text-gray-500">
            {isPercentageDiscount ? 'Coinsurance' : 'Copay'} does not include cost of medication
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInsuranceBanner;

import { Common } from '@thecvlb/design-system';
import { useDispatch } from 'react-redux';

import Automation from 'components/modals/Automation';
import { openModal } from 'store/modal/modalSlice';

const EditCell: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex gap-1">
      <button
        data-testid="pencil_icon"
        onClick={() => dispatch(openModal({ modalContent: <Automation id={id} />, hideClose: true, size: 'lg' }))}
      >
        <Common.Icon name="pencil" className="size-4 hover:text-primary-500" />
      </button>
    </div>
  );
};

export default EditCell;

import { ChangeEvent, cloneElement, FocusEventHandler, useEffect, useState } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';

import { getNewValue, getNewValueOnBlur } from './customTimeInput.settings';
import { CustomTimeInputProps } from './customTimeInput.types';

const CustomTimeInput = ({ initialTime, input, onChange, className, onBlur, ...props }: CustomTimeInputProps) => {
  const [value, setValue] = useState(initialTime);

  const inputClassName = classNames(
    'mr-0.5 w-1/2 border-none p-0 text-sm text-gray-500 focus:ring-0 focus:ring-offset-0',
    className,
  );

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const updatedValue = getNewValueOnBlur(value);
    setValue(updatedValue);
    onBlur?.(event);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = getNewValue(value, event.target.value);
    if (newValue) {
      setValue(newValue);
      if (onChange) {
        onChange(event);
      }
    }
  };

  useEffect(() => {
    if (initialTime && initialTime && initialTime !== dayjs(value, DateFormat.hh_mm).format(DateFormat.hh_mm)) {
      setValue(initialTime);
    }
    // should depend on initialTime only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTime]);

  if (input) {
    return cloneElement(input, {
      ...props,
      onChange: handleChange,
      value,
    });
  }

  return (
    <input
      {...props}
      onBlur={handleBlur}
      onChange={handleChange}
      type="text"
      value={value}
      className={inputClassName}
    />
  );
};

export default CustomTimeInput;

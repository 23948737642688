import { useEffect, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Common } from '@thecvlb/design-system/lib/src';
import { useController, useFormContext } from 'react-hook-form';

import Loader from 'components/common/Loader';
import RadioButtonList from 'components/common/RadioButtonList';
import { PlanCodesProps } from 'enums/appointmentStatus';
import { defaultCoupon } from 'pages/CrossSell/crossSell.settings';
import { useGetMembershipPlansQuery } from 'store/crossSell/crossSellSlice';
import { useGetMedicationsQuery } from 'store/weightManagement/weightManagementSlice';
import { getDefaultPricePoint } from 'utils/helpers';

import { MembershipPlanProps, PricePointProps } from './planType.types';
// TODO: Reuse functions from /components/modals/ChangePlan/ChoosePlan/choosePlan.settings.tsx
import {
  choosePlanTabs,
  FlexCare,
  getPlanLabel,
  getPricePoints,
  preventPricePointInPatientsState,
} from '../ChoosePlan/choosePlan.settings';
import { ChoosePlanTab } from '../ChoosePlan/choosePlan.types';

const priceClassList = [
  {
    label: 'Standard',
    value: 0,
  },
  {
    label: 'Member cross-sell',
    value: 1,
  },
];

const planTypesList = [
  {
    label: 'Weight Management',
    value: 0,
  },
  {
    label: 'Primary Care',
    value: 1,
  },
];

const PlanType: React.FC<{ patientId: string }> = ({ patientId }) => {
  const { control, formState, setValue, watch, resetField } = useFormContext();
  const { data, isLoading, isFetching } = useGetMembershipPlansQuery();

  watch();
  const patientState = watch('state.label');

  const { data: medications, isLoading: isFetchingMedications } = useGetMedicationsQuery(
    { state: patientState, userId: patientId },
    { refetchOnMountOrArgChange: true },
  );

  const showLoader = isLoading || isFetching || isFetchingMedications;

  const [selectPriceClass, setSelectPriceClass] = useState(0);
  const [selectPlanType, setSelectPlanType] = useState(0);
  const [activePeriodsTab, setActivePeriodsTab] = useState(ChoosePlanTab.EveryMonth);
  const [sortedData, setSortedData] = useState<MembershipPlanProps[]>([]);
  const { field } = useController({
    name: 'planType',
    control,
    defaultValue: null,
    rules: {
      required: {
        value: true,
        message: 'Plan type is required',
      },
    },
  });
  const showMembership = (
    membership: MembershipPlanProps,
    selectPriceClassValue: number,
    selectPlanTypeValue: number,
  ) => {
    return (
      !membership.isComponent &&
      (membership.priceClass === 'all' ||
        (!selectPriceClassValue
          ? membership.priceClass === 'standard'
          : membership.priceClass === 'member-cross-sell')) &&
      (!selectPlanTypeValue
        ? membership.planCode === PlanCodesProps.WeightManagementMembership
        : membership.planCode !== PlanCodesProps.WeightManagementMembership)
    );
  };

  const handleChange = (membershipPlan: MembershipPlanProps, pricePoint: PricePointProps) => {
    setValue('planType', {
      plan: membershipPlan,
      pricePoint: pricePoint,
    });
    setValue('creditCardAttributes.couponCode', defaultCoupon);
  };

  const handleClickTab = (button: Common.DataItemProps) => {
    setActivePeriodsTab(button.label as ChoosePlanTab);
  };

  const handleChangePriceClass = (value: string) => {
    setSelectPriceClass(+value);
  };

  const handleChangePlanType = (value: string) => {
    setSelectPlanType(+value);
  };

  const isSelectPriceClass = (value: number) => value === selectPriceClass;
  const isSelectPlanType = (value: number) => value === selectPlanType;

  const setInitialPlan = () => {
    let initialItemSet = false;

    sortedData?.forEach((plan) => {
      const pricePoints = getPricePoints(
        plan.pricePoints,
        activePeriodsTab,
        plan.planCode === PlanCodesProps.WeightManagementMembership,
      );

      if (!pricePoints?.length && initialItemSet === false) {
        resetField('planType');
        return;
      }

      pricePoints?.forEach((pricePoint) => {
        const disablePricePoint = preventPricePointInPatientsState(pricePoint, medications, patientState);

        if (!disablePricePoint && initialItemSet === false) {
          handleChange(plan, pricePoint);
          initialItemSet = true;
        }
      });
    });
  };

  useEffect(() => {
    setInitialPlan();
  }, [activePeriodsTab, selectPriceClass, sortedData]);

  useEffect(() => {
    if (data) {
      const newSortedData = [...(data || [])]
        ?.sort((a, b) => {
          const pricePointA = getDefaultPricePoint(a.pricePoints);
          const pricePointB = getDefaultPricePoint(b.pricePoints);

          return Number(pricePointA?.totalCost) - Number(pricePointB?.totalCost);
        })
        .filter((membership) => showMembership(membership, selectPriceClass, selectPlanType));

      setSortedData(newSortedData);
    }
  }, [data, selectPriceClass, selectPlanType]);

  return (
    <>
      {showLoader && <Loader isVisible />}
      <div className="mb-4 flex flex-col gap-4">
        <h2 className="text-base font-semibold text-gray-700">Price class</h2>
        <div data-testid="prices_classes_block" className="flex gap-4">
          {priceClassList.map((priceClass) => {
            return (
              <Common.RadioButton
                dataTestId="price_class"
                key={priceClass.value}
                color="blue"
                size="md"
                value={priceClass.value}
                checked={isSelectPriceClass(priceClass.value)}
                onChange={(e) => {
                  handleChangePriceClass(e.target.value);
                }}
              >
                {priceClass.label}
              </Common.RadioButton>
            );
          })}
        </div>
        <h2 className="text-base font-semibold text-gray-700">Plan</h2>
        <div data-testid="plan_types_block" className="flex gap-4">
          {planTypesList.map((planType) => {
            return (
              <Common.RadioButton
                dataTestId="plan_types"
                key={planType.value}
                color="blue"
                size="md"
                value={planType.value}
                checked={isSelectPlanType(planType.value)}
                onChange={(e) => {
                  handleChangePlanType(e.target.value);
                }}
              >
                {planType.label}
              </Common.RadioButton>
            );
          })}
        </div>
      </div>
      <div className="mb-4">
        <h2 className="mb-4 text-base font-semibold text-gray-700">Billing frequency</h2>
        <Common.Tabs
          onChange={handleClickTab}
          data={choosePlanTabs}
          defaultSelected={[activePeriodsTab]}
          type="bar"
          className="rounded-full border border-gray-100 p-1 shadow"
        />
      </div>

      <div className="flex flex-col">
        {sortedData?.map((plan, i, { length }) => {
          const pricePoints = getPricePoints(
            plan.pricePoints,
            activePeriodsTab,
            plan.planCode === PlanCodesProps.WeightManagementMembership,
          );
          const isFlexCare = plan.planName === FlexCare;

          return pricePoints?.map((pricePoint) => {
            const disablePricePoint = preventPricePointInPatientsState(pricePoint, medications, patientState);

            return (
              (!!pricePoint || isFlexCare) && (
                <RadioButtonList
                  label={getPlanLabel(plan.planName, isFlexCare, pricePoint)}
                  value={pricePoint.planPricePointId}
                  color="blue"
                  key={pricePoint.planPricePointId}
                  isSelected={
                    (field.value?.plan?.planName?.includes(plan.planName) &&
                      field.value?.pricePoint?.planPricePointId?.includes(pricePoint.planPricePointId)) ||
                    false
                  }
                  handleChange={() => handleChange(plan, pricePoint)}
                  {...(isFlexCare &&
                    pricePoint.planPricePointId === 'limited-access' && { className: 'order-first', first: true })}
                  last={length - 1 === i}
                  disabled={disablePricePoint}
                />
              )
            );
          });
        })}
      </div>

      <ErrorMessage
        errors={formState.errors}
        name="planType"
        render={({ message }) => <p className="text-xs text-red">{message}</p>}
      />
    </>
  );
};

export default PlanType;

import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';

import Loader from 'components/common/Loader';
import BodyImageGallery from 'components/tasks/slidingPane/BodyImage/BodyImageGallery';
import { useAppDispatch } from 'hooks/redux';
import { BodyImageManualStatus, BodyImageManualStatuses } from 'models/bodyImage.types';
import { BodyImage } from 'models/tasks.types';
import { closeModal } from 'store/modal/modalSlice';
import { useUpdateVerifyBodyImageMutation } from 'store/patients/patientsSlice';

import { getBodyImageText } from './bodyImageModal.settings';
import { BodyImageModalProps } from './bodyImageModal.types';

const BodyImageModal: React.FC<BodyImageModalProps> = ({ bodyImage, patientId, onChangeBodyImage }) => {
  const dispatch = useAppDispatch();
  const [bodyImageInfo, setBodyImageInfo] = useState<BodyImage>(bodyImage);
  const [updateVerifyBodyImage, { isLoading }] = useUpdateVerifyBodyImageMutation();

  const isVerified = bodyImageInfo.manualStatus === BodyImageManualStatuses.ACCEPTED;
  const isInvalid = bodyImageInfo.manualStatus === BodyImageManualStatuses.INVALID;
  const isNotSubmitted = !bodyImageInfo.documents.length;
  const disableSubmit = !bodyImageInfo.documents.length;

  const text = getBodyImageText(isNotSubmitted, isVerified, isInvalid);
  const color = isNotSubmitted ? 'gray' : isVerified ? 'green' : 'red';
  const icon = isVerified ? 'check' : 'close';

  const handleChangeBodyImage = (newBodyImage: Partial<BodyImage>) => {
    setBodyImageInfo((prevBodyImage) => ({ ...prevBodyImage, ...newBodyImage }));
    onChangeBodyImage(newBodyImage);
  };

  const handleVerifiedBodyImage = (manualStatus: BodyImageManualStatus | null) => {
    updateVerifyBodyImage({ patientId, manualStatus })
      .unwrap()
      .then(() => {
        handleChangeBodyImage({ manualStatus });
        dispatch(closeModal());
      });
  };

  useEffect(() => {
    setBodyImageInfo(bodyImage);
  }, [bodyImage]);

  return (
    <div className="p-6">
      <Loader isVisible={isLoading} />
      <h2 className="mb-2 text-xl font-bold text-gray-700">Body image verification</h2>

      {!isNotSubmitted && <Common.ColorTag color={color} text={text} icon={icon} />}

      <div className="mt-6 flex justify-center gap-2">
        <BodyImageGallery
          documents={bodyImageInfo.documents}
          onChangeBodyImage={handleChangeBodyImage}
          patientId={patientId}
        />
      </div>

      <div className="mt-6 grid w-full grid-cols-2 gap-x-2">
        {isNotSubmitted ? (
          <Common.Button
            className="col-span-2 flex justify-self-end"
            color="blue"
            size="sm"
            disabled={disableSubmit}
            onClick={() => handleVerifiedBodyImage(BodyImageManualStatuses.ACCEPTED)}
          >
            Submit
          </Common.Button>
        ) : (
          <>
            <Common.Button
              className="flex w-full justify-center"
              color="red"
              size="sm"
              onClick={() => handleVerifiedBodyImage(BodyImageManualStatuses.INVALID)}
            >
              Mark as invalid
            </Common.Button>
            <Common.Button
              className="flex w-full justify-center"
              color="white-alt"
              size="sm"
              preIcon="check"
              onClick={() => handleVerifiedBodyImage(BodyImageManualStatuses.ACCEPTED)}
            >
              Accept photo
            </Common.Button>
          </>
        )}
      </div>
    </div>
  );
};

export default BodyImageModal;

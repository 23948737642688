import { Common } from '@thecvlb/design-system';

import Success from 'assets/icons/modals/success.svg?react';

const CrossSellAlert: React.FC<{ handleClick?: () => void }> = ({ handleClick }) => {
  return (
    <div data-testid="payment_success_popup" className="flex w-[360px] flex-col items-center justify-center p-7">
      <Success className="size-20" />
      <h2 className="mt-4 text-2xl text-primary">Payment Successful!</h2>
      <p className="mt-4 text-center text-base text-gray-700">Patient successfully created.</p>
      <Common.Button color="blue" form="pill" className="mt-6" postIcon="arrow-right-small" onClick={handleClick}>
        Continue
      </Common.Button>
    </div>
  );
};

export default CrossSellAlert;

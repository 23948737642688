import { useCallback, useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import isEmpty from 'lodash/isEmpty';

import AddFrontDeskTicket from 'components/modals/AddFrontDeskTicket';
import CreateNewChannel from 'components/modals/CreateNewChannel';
import MessagesTypePicker from 'components/tasks/slidingPane/chat/MessagesTypePicker';
import { MessageType, Status } from 'enums/messages';
import { useClientMessages } from 'hooks/common/useClientMessages';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { channelsSelectors, selectChannels } from 'store/channels/channelsSlice';
import { selectChat } from 'store/chat/chatSlice';
import { openModal } from 'store/modal/modalSlice';
import { resetSMSs, selectLatestSMS, selectSMS } from 'store/sms/smsSlice';
import { selectCanCreateFrontDeskChannel } from 'store/user/userSlice';

import Channel from './Channel/Channel';
import ChannelSkeleton from './Channel/Skeleton';
import { SideBarProps } from './sidebar.types';

const selectSidebarState = createSelector(
  [
    selectChat,
    channelsSelectors.selectAll,
    selectLatestSMS,
    selectChannels,
    selectSMS,
    selectCanCreateFrontDeskChannel,
  ],
  (chat, channelsList, latestSMS, channels, sms, canCreateFrontDeskChannel) => ({
    channels: channelsList,
    latestSMS,
    isLoading: channels.loadingChannelsStatus === Status.Idle || channels.loadingChannelsStatus === Status.Pending,
    isRejected: chat.loadingMessagesStatus === Status.Rejected || channels.loadingChannelsStatus === Status.Rejected,
    isLoadingSMS: sms.status === Status.Idle,
    canCreateFrontDeskChannel,
  }),
);

const Sidebar: React.FC<SideBarProps> = ({ changeActiveTab, patientId, messageType, handleChange }) => {
  const dispatch = useAppDispatch();
  const validMessageType =
    messageType !== MessageType.SMS && messageType !== MessageType.StaffNote ? messageType : MessageType.Medical;

  const useMessages = useClientMessages({ type: validMessageType });
  const { channels, latestSMS, isLoading, isLoadingSMS, canCreateFrontDeskChannel } =
    useAppSelector(selectSidebarState);
  const { channelDetails } = useMessages();

  const isSMS = messageType === MessageType.SMS;
  const showCreateNewChannelButton = messageType === MessageType.Medical || canCreateFrontDeskChannel;
  const sms = useMemo(
    () => ({
      patientId,
      channelId: 'sms',
      channelTitle: 'SMS',
      channelDescription: 'sms',
      latestMessage: latestSMS?.message,
      latestMessageDate: latestSMS?.createdAt,
      unreadMessageCount: 0,
    }),
    [latestSMS?.createdAt, latestSMS?.message, patientId],
  );

  const handleChangeTab = useCallback(
    (selectedTab: MessageType) => {
      changeActiveTab(selectedTab);
      if (selectedTab !== MessageType.SMS) dispatch(resetSMSs());
    },
    [changeActiveTab, dispatch],
  );

  const handleClickNew = () => {
    if (messageType === 'medical') {
      dispatch(
        openModal({
          size: 'sm',
          hideClose: true,
          modalContent: <CreateNewChannel patientId={patientId} />,
        }),
      );
    } else {
      dispatch(openModal({ size: 'sm', hideClose: true, modalContent: <AddFrontDeskTicket patientId={patientId} /> }));
    }
  };

  return (
    <>
      <div className="max-h-14 border-b border-gray-200 bg-gray-50 px-5 py-2">
        <MessagesTypePicker location="Patient" messagesType={messageType} setMessagesType={handleChangeTab} />
      </div>
      {/* Tab content */}
      <div className="overflow-x-hidden overflow-y-scroll scrollbar-hide">
        {!isSMS && !isLoading && !isEmpty(channels)
          ? channels.map((channel) => (
              <Channel
                channel={channel}
                handleChange={handleChange}
                key={channel.channelId}
                isActive={channelDetails?._id === channel.channelId}
              />
            ))
          : isLoading && !isSMS && <ChannelSkeleton />}
        {isSMS && !isLoadingSMS ? <Channel channel={sms} isActive /> : isSMS && <ChannelSkeleton />}

        {showCreateNewChannelButton && !isSMS && (
          <button
            data-testid="create_new_request"
            className="relative flex items-center gap-2 p-4 pl-10 font-bold text-blue-500"
            onClick={handleClickNew}
          >
            <Common.Icon name="plus" className="size-4" />
            <span className="flex-1 text-left text-base">Add channel</span>
          </button>
        )}
      </div>
    </>
  );
};

export default Sidebar;

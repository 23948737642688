import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { MEMBERSHIP_PROSPECT, PROSPECT_STATUS_OPTIONS } from 'constants/prospect';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import { useGetLicensedInQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { defaultValues } from './prospectFilterForm.settings';
import { ProspectFilterFormTypes } from './prospectFilterForm.types';

const ProspectFiltersForm = () => {
  const { control, handleSubmit, reset, watch } = useForm<ProspectFilterFormTypes>({
    reValidateMode: 'onChange',
  });
  const { data: licensedInData } = useGetLicensedInQuery();
  const [formData, setFormData] = useTableFiltersData('prospects', defaultValues, reset);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = (data: ProspectFilterFormTypes) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      patientName: data?.patientName,
      planId: data?.planId?.value,
      email: data?.email,
      state: data?.state?.label,
      prospectStatus: data?.prospectStatus?.value,
      phone: data?.phone,
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <>
      <form className="w-[600px]" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-4 border-r border-gray-200 pr-7">
            <InputField name="patientName" label="Name" type="text" placeholder="Enter name" control={control} />
            <ControlledSelect
              control={control}
              labelDirection="col"
              placeholder="Select plan..."
              options={MEMBERSHIP_PROSPECT}
              label="Plan"
              name="planId"
              defaultValue={formData?.planId}
            />
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={licensedInData}
              placeholder="Select state..."
              label="State"
              name="state"
              defaultValue={formData?.state}
            />
          </div>
          <div className="flex flex-col gap-4 pl-7">
            <InputField name="email" label="Email" type="text" placeholder="Enter email" control={control} />
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={PROSPECT_STATUS_OPTIONS}
              placeholder="Select status..."
              label="Status"
              name="prospectStatus"
              defaultValue={formData?.state}
            />
            <InputField name="phone" label="Phone" type="text" placeholder="Enter phone number" control={control} />
          </div>
        </div>
        <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
      </form>
    </>
  );
};

export default ProspectFiltersForm;

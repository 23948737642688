import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Call } from '@twilio/voice-sdk';

import { useAppSelector } from 'hooks/redux';
import { PatientProps } from 'store/patients/patients.types';
import { selectPatient } from 'store/patients/patientsSlice';

import { InitiatePhoneCallProps, PhoneCallContextProps } from './phoneCallContext.types';

const defaultPhoneCallContextValues: PhoneCallContextProps = {
  call: null,
  device: null,
  hasGlobalInstance: false,
  hasSlidingPanelInstance: false,
  patientId: null,
  phoneCallPatient: null,
  isPhoneCallActive: false,
  showCallCard: false,
  token: null,
  initiatePhoneCallProps: () => {},
  initiateCallToPatient: () => {},
  resetPhoneCallProps: () => {},
  setDevice: () => {},
  setHasGlobalInstance: () => {},
  setHasSlidingPanelInstance: () => {},
  setPatientId: () => {},
  setPhoneCallPatient: () => {},
  setIsPhoneCallActive: () => {},
  setShowCallCard: () => {},
  setToken: () => {},
};

const PhoneCallContext = createContext<PhoneCallContextProps>(defaultPhoneCallContextValues);

// NOTE: we have implemented the initiate and reset functions here
// NOTE: and we use them in the components, where the phone call button or the phone number is clicked

const selectChatState = createSelector([selectPatient], (patient) => ({
  patient: patient.patientInfo,
}));

const PhoneCallProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { patient } = useAppSelector(selectChatState);

  const [patientFromTheStore, setPatientFromTheStore] = useState(patient);
  const [token, setToken] = useState(defaultPhoneCallContextValues.token);
  const [patientId, setPatientId] = useState(defaultPhoneCallContextValues.patientId);
  const [showCallCard, setShowCallCard] = useState(defaultPhoneCallContextValues.showCallCard);
  const [hasSlidingPanelInstance, setHasSlidingPanelInstance] = useState(
    defaultPhoneCallContextValues.hasSlidingPanelInstance,
  );
  const [hasGlobalInstance, setHasGlobalInstance] = useState(defaultPhoneCallContextValues.hasGlobalInstance);
  const [device, setDevice] = useState(defaultPhoneCallContextValues.device);
  const [call, setCall] = useState(defaultPhoneCallContextValues.call);
  const [phoneCallPatient, setPhoneCallPatient] = useState(defaultPhoneCallContextValues.phoneCallPatient);
  const [isPhoneCallActive, setIsPhoneCallActive] = useState(defaultPhoneCallContextValues.isPhoneCallActive);

  const callRef = useRef(call);

  useEffect(() => {
    callRef.current = call;
  }, [call]);

  // NOTE: keep the active phone call in the context to
  // have ability to disconnect from it when it's needed
  const initiateCallToPatient = (callToPatient: Call) => {
    if (callToPatient) {
      setCall(callToPatient);
    }
  };

  const initiatePhoneCallProps = useCallback(
    ({ isSlidingPanel }: InitiatePhoneCallProps) => {
      if (patientFromTheStore) {
        setPatientId(patientFromTheStore._id);
        setPhoneCallPatient(patientFromTheStore as PatientProps);

        if (isSlidingPanel) {
          setHasSlidingPanelInstance(true);
        } else {
          setHasGlobalInstance(true);
        }
        setShowCallCard(true);
        setIsPhoneCallActive(false);
      } else {
        setDevice(null);
        setShowCallCard(false);
      }
    },
    [patientFromTheStore],
  );

  // NOTE: to keep the phoneCallPatient and patientId in sync
  useEffect(() => {
    if (patient) {
      setPatientFromTheStore(patient);
      setPatientId(patient._id);
    }
  }, [patient]);

  // NOTE: regenerate the Phone call pop-up if:
  //  the user navigates to another task
  //  and the Phone call pop-up is already shown
  //  and the isPhoneCallActive is false
  useEffect(() => {
    const shouldRefreshPhoneCallPopup =
      patient && patientId && hasSlidingPanelInstance && !isPhoneCallActive && isPhoneCallActive !== null;

    if (shouldRefreshPhoneCallPopup) {
      const isSlidingPanel = true;

      initiatePhoneCallProps({
        isSlidingPanel,
      });
    }
  }, [patient, patient?._id, patientId, hasSlidingPanelInstance, isPhoneCallActive]);

  const resetPhoneCallProps = () => {
    // NOTE: to be sure if the call active, it will be disconnected
    if (callRef.current) {
      callRef.current.disconnect();
    }

    setShowCallCard(false);
    setToken(null);
    setPatientId(null);
    setHasSlidingPanelInstance(false);
    setHasGlobalInstance(false);
    setDevice(null);
    setPhoneCallPatient(null);
    setIsPhoneCallActive(null);
  };

  const values = useMemo(
    () => ({
      token,
      setToken,
      patientId,
      setPatientId,
      device,
      setDevice,
      isPhoneCallActive,
      setIsPhoneCallActive,
      showCallCard,
      hasSlidingPanelInstance,
      hasGlobalInstance,
      setShowCallCard,
      setHasSlidingPanelInstance,
      setHasGlobalInstance,
      call,
      setCall,
      initiatePhoneCallProps,
      initiateCallToPatient,
      resetPhoneCallProps,
      phoneCallPatient,
      setPhoneCallPatient,
    }),
    [
      patientId,
      showCallCard,
      isPhoneCallActive,
      token,
      phoneCallPatient,
      hasSlidingPanelInstance,
      hasGlobalInstance,
      device,
      call,
    ],
  );

  return <PhoneCallContext.Provider value={values}>{children}</PhoneCallContext.Provider>;
};

const usePhoneCall = () => {
  const context = useContext(PhoneCallContext);
  if (context === undefined) {
    throw new Error('usePhoneCall must be used within PhoneCallProvider');
  }
  return context;
};

export { PhoneCallProvider, usePhoneCall };

import { useMemo, useState } from 'react';

import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import queryString from 'query-string';
import { FieldValues, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import DatePickerInput from 'components/common/DatePickerInput';
import AutocompleteInput from 'components/common/form/AutocompleteInput';
import SelectedItemCounter from 'components/common/SelectedItemCounter';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { STATUS_OPTIONS } from 'constants/status';
import { SEARCH_USER_TYPE } from 'constants/user';
import { TagTypes } from 'enums/tagTypes';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import { useGetMembershipPlansQuery } from 'store/crossSell/crossSellSlice';
import { useGetLicensedInQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { useGetTagsListQuery } from 'store/patients/patientsSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';
import { AT_LEAST_ONE_SPEC_CHAR_REGEXP } from 'utils/regExp';
import { getGroupedTags } from 'utils/tags';

import { defaultPatientFilters } from './patientFiltersForm.settings';
import ControlledMultiSelect from '../../forms/controlled/ControlledMultiSelect';

const PatientFiltersForm = () => {
  const { control, watch, handleSubmit, reset, setValue, getValues } = useForm<FieldValues>({
    reValidateMode: 'onChange',
  });

  const { data: membershipPlans } = useGetMembershipPlansQuery();
  const { data: response } = useGetTagsListQuery({ tagTypes: [TagTypes.Both, TagTypes.Manual, TagTypes.System] });
  const { data: stateList } = useGetLicensedInQuery();

  const patientTags = useMemo(() => {
    return response?.data.map((tag) => ({
      label: tag.name,
      value: tag._id,
    }));
  }, [response?.data]);

  const patientTagsOptions = useMemo(() => getGroupedTags(response?.data || []), [response?.data]);
  const stateOptions = useMemo(() => stateList?.map(({ label }) => ({ label, value: label })), [stateList]);

  const [createdAtDate, setCreatedAtDate] = useState<Date>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useTableFiltersData('patients', defaultPatientFilters, reset, patientTags);

  const membershipPlansOptions = useMemo(
    () =>
      uniqBy(
        membershipPlans?.map((plan) => ({
          label: plan.planName,
          value: plan.planName,
        })),
        'label',
      ),
    [membershipPlans],
  );

  const onSubmit = (data: FieldValues) => {
    setFormData({ ...defaultPatientFilters, ...data });
    const parsedUrl = queryString.parse(location.search);

    const filters = {
      ...parsedUrl,
      pageNo: '0',
      email: data?.email,
      doctor: data?.doctor?.replace(AT_LEAST_ONE_SPEC_CHAR_REGEXP, '').trim(),
      patient: data?.patient?.replace(AT_LEAST_ONE_SPEC_CHAR_REGEXP, '').trim(),
      status: data?.status?.value,
      membership: data?.membership?.value,
      createdAt: data?.createdAt,
      uniqueId: data?.uniqueId,
      tags: data?.tags && _map(data.tags, 'value'),
      states: data?.states && _map(data.states, 'value'),
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }

    dispatch(closeModal());
  };

  watch();

  return (
    <>
      <form className="w-[410px]" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 border-b border-gray-200 pb-4">
          <ControlledMultiSelect
            control={control}
            labelDirection="col"
            options={stateOptions}
            placeholder="Select state"
            label="States"
            name="states"
            defaultInputValue={formData?.states}
          />
          <SelectedItemCounter count={getValues('states')?.length} className="mt-1" hideZero />
        </div>

        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-4 border-r border-gray-200 pr-7">
            <AutocompleteInput
              dataTestId="patient_scope"
              userType={SEARCH_USER_TYPE.patient}
              name="patient"
              labelDirection="col"
              placeholder="Enter patient"
              label="Patient"
              setValue={setValue}
              value={formData?.patient}
            />
            <AutocompleteInput
              dataTestId="doctor_name_scope"
              userType={SEARCH_USER_TYPE.physician}
              labelDirection="col"
              name="doctor"
              label="Doctor"
              placeholder="Enter physician"
              setValue={setValue}
              value={formData?.doctor}
            />
            <InputField
              dataTestId="email_scope"
              name="email"
              label="Email"
              type="text"
              placeholder="Enter email"
              control={control}
            />
            <ControlledSelect
              dataTestId="membership_scope"
              control={control}
              labelDirection="col"
              options={membershipPlansOptions}
              placeholder="Enter membership"
              label="Membership"
              name="membership"
              defaultValue={formData?.membership}
            />
          </div>
          <div className="flex flex-col gap-4 pl-7">
            <InputField
              dataTestId="uniqueId_scope"
              name="uniqueId"
              label="Unique ID"
              type="text"
              placeholder="Enter ID"
              control={control}
            />
            <DatePickerInput
              dataTestId="date_joined_scope"
              placeholder="Select date"
              label="Creation Date"
              labelDirection="col"
              inputValue={getValues('createdAt')}
              setInputValue={(value) => setValue('createdAt', value)}
              selectedDate={createdAtDate}
              setSelectedDate={setCreatedAtDate}
              startAllowedDate={dayjs().subtract(100, 'year').toDate()}
              lastAllowedDate={new Date()}
              captionLayout={'dropdown'}
              wrapperClasses="w-full relative mr-14"
              size="sm"
              isRtL
            />
            <ControlledSelect
              dataTestId="status_dropdown_scope"
              control={control}
              labelDirection="col"
              options={STATUS_OPTIONS}
              placeholder="Select status"
              label="Status"
              name="status"
              defaultValue={formData?.status}
            />
            <ControlledMultiSelect
              dataTestId="tags_select"
              control={control}
              label="Patient tag"
              name="tags"
              placeholder="Select tag"
              options={patientTagsOptions}
              labelDirection="col"
              isGrouped
            />
          </div>
        </div>
        <Footer
          appliedFiltersTagsOptions={patientTags}
          onClick={() => handleReset(dispatch, reset, defaultPatientFilters, navigate)}
        />
      </form>
    </>
  );
};

export default PatientFiltersForm;

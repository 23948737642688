import { Common } from '@thecvlb/design-system';

import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';

import { ModalProps } from './modal.types';

const Modal: React.FC<ModalProps> = ({ children, size, isOpen, padding, hideClose, drag, hideCloseButton }) => {
  const dispatch = useAppDispatch();

  // TODO: Fix the focus of select fields
  /**
   * Description of the focus problem.
   * The 'hideCloseButton' field was added because it helps to avoid the following problem:
   * If we add the AutocompleteMultiSelect field (or other selects from the system design) to a modal window,
   * when we open the modal window, the AutocompleteMultiSelect field is immediately focused.
   * But if we pass hideClose=true, this focus disappears.
   */

  return (
    <Common.Modal
      isOpen={isOpen}
      {...(!hideClose && { close: () => dispatch(closeModal()) })}
      size={size}
      padding={padding}
      autoScroll={false}
      zIndex={120}
      drag={drag}
      hideCloseButton={hideCloseButton}
    >
      {children}
    </Common.Modal>
  );
};

export default Modal;

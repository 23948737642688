import { MouseEventHandler, SetStateAction, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useClickAway } from 'react-use';

import { DateFormat } from 'enums/dateFormats';
import { parseDateForTodayAndTomorrow } from 'utils/datesAndTime';

import { AvailabilityDropdownProps } from './availabilityDropdown.types';

const AvailabilityDropdown: React.FC<AvailabilityDropdownProps> = ({ slots, slot, setSlot }) => {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);
  const optionsClasses = classNames(
    'absolute right-0 left-0 flex flex-col divide-y bg-white top-full ' +
      'shadow rounded-lg mt-1 origin-top transition ease-in-out ' +
      'duration-300 transform max-h-32 overflow-y-auto z-10',
    showOptions ? 'scale-y-100' : 'scale-y-0',
  );
  const generateKey = (startTime: string, key: number) => {
    return startTime + key;
  };

  useClickAway(ref, () => setShowOptions(false));
  const handleOnClickDropdown: MouseEventHandler<HTMLButtonElement> = (event) => {
    event?.stopPropagation();
    setShowOptions(!showOptions);
  };
  const handleSelectTime = (sl: SetStateAction<{ startTime: string; endTime: string }>) => {
    setSlot(sl);
    setShowOptions(false);
  };

  return (
    <>
      {slots?.length && (
        <div className="relative mb-4" ref={ref}>
          <div className={optionsClasses}>
            {slots.map((sl, i) => (
              <button
                type="button"
                data-testid="available_time"
                className="block py-3 text-center transition-all hover:bg-primary hover:text-white"
                key={generateKey(sl.startTime, i)}
                onClick={() => handleSelectTime(sl)}
              >
                {dayjs(sl.startTime).format(DateFormat.h_mma_z)}
              </button>
            ))}
          </div>
          <Common.Button
            type="button"
            onClick={handleOnClickDropdown}
            style="pill"
            size="md"
            color="green-alt"
            className="text-primary"
          >
            <Common.Icon name="clock" className="ml-1.5 size-5 text-primary" />
            {slot?.startTime ? (
              <span data-testid="appo_start_time" className="text-primary">{`${parseDateForTodayAndTomorrow(
                slot?.startTime,
              )}, ${dayjs(slot?.startTime).format(DateFormat.h_mma_z)}`}</span>
            ) : (
              ''
            )}
            <Common.Icon name="arrow-down" className="ml-1.5 size-4 text-primary" />
          </Common.Button>
        </div>
      )}
    </>
  );
};

export default AvailabilityDropdown;

export const CATEGORY_OPTIONS = [
  { value: 'Primary care', label: 'Primary care', id: '1' },
  { value: 'Urgent care', label: 'Urgent care', id: '2' },
  { value: 'Chronic care', label: 'Chronic care', id: '3' },
];

export const LENGTH_OPTIONS = [
  { value: '15', label: '15 minutes', id: '1' },
  { value: '20', label: '20 minutes', id: '2' },
  { value: '30', label: '30 minutes', id: '3' },
  { value: '45', label: '45 minutes', id: '4' },
];
export const LENGTH_OPTIONS_WITH_DEFAULT = [{ value: '0', label: 'Use default length', id: '5' }, ...LENGTH_OPTIONS];

export const ASAP_OPTION_DATE = 'asap';
export const APPROX_WAIT_TIME = '~30 min wait';

export const DEFAULT_APPOINTMENT_TYPE = 'Health & Wellness';
export const DEFAULT_WEIGHT_MANAGEMENT_TYPE = 'Weight program follow-up';
export const DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE = 'Weight Management';

export const CATEGORY_LABELS = [
  { singular: 'Appointment', plural: 'Appointments' },
  { singular: 'Request', plural: 'Requests' },
  { singular: 'Front desk', plural: 'Front desk' },
  { singular: 'Message', plural: 'Messages' },
  { singular: 'Async', plural: 'Async' },
  { singular: 'Pre-consult', plural: 'Pre-consult' },
  { singular: 'Post-consult', plural: 'Post-consult' },
  { singular: 'Review', plural: 'Review' },
  { singular: 'Reminder', plural: 'Reminders' },
  { singular: 'Onboard', plural: 'Onboarding' },
];

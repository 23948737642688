import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { useAppDispatch } from 'hooks/redux';
import socketStaff from 'socket/socketStaff';
import type { SuggestionStreamChunk } from 'store/aiAssistant/aiAssistant.types';
import { RequestStatus, SuggestedMessageRequest } from 'store/aiAssistant/aiAssistant.types';
import {
  resetAIasistantState,
  setHashKey,
  setMessageSuggestion,
  useGetStreamSuggestedResponseMutation,
  useGetSuggestedResponseMutation,
  useSendAnalyticsMutation,
} from 'store/aiAssistant/aiAssistantSlice';

import PasteResponse from './PasteResponse';
import { PulsingCursor } from './PulsingCursor';
import { Skeleton } from './Skeleton';
import { SuggestionContent } from './suggestion.styled';
import type { SuggestionsProps } from './suggestions.types';
import SuggestionScore from './SuggestionScore';
import ToggleSuggestion from './ToggleSuggestion';

const renderContent = ({
  isOpen,
  content,
  isLoading,
  isStream,
}: {
  isOpen: boolean;
  content: string | JSX.Element;
  isLoading: boolean;
  isStream: boolean;
}) => {
  return (
    <SuggestionContent $isOpen={isOpen} className={isOpen ? '' : 'flex-auto'}>
      {isLoading && !isStream ? (
        <Skeleton isOpen />
      ) : typeof content === 'string' ? (
        <>
          <ReactMarkdown className="ai-suggested-response" remarkPlugins={[remarkGfm]}>
            {content}
          </ReactMarkdown>
          {isLoading && <PulsingCursor />}
        </>
      ) : (
        <>
          {content}
          {isLoading && <PulsingCursor />}
        </>
      )}
    </SuggestionContent>
  );
};

const Suggestions = ({ patientId, question, isPH, isMessageTask, isMessageInputFocused }: SuggestionsProps) => {
  const dispatch = useAppDispatch();
  const isStream = !useFlag('disable-ai-message-streaming');
  const [getSuggestedResponse, { data, isLoading, isError }] = useGetSuggestedResponseMutation();
  const [sendAnalytics] = useSendAnalyticsMutation();
  const [getStreamSuggestedResponse, { data: streamData, isError: isStreamError }] =
    useGetStreamSuggestedResponseMutation();
  const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
  const [isInputFocusedOnce, setIsInputFocusedOnce] = useState(false);

  const [isSameRequestInProgress, setIsSameRequestInProgress] = useState(false);

  const [isStreamLoading, setIsStreamLoading] = useState(false);
  const [streamScore, setStreamScore] = useState<number | null>(null);

  const [streamResponse, setStreamResponse] = useState<SuggestionStreamChunk[]>([]);
  const [rephrasedQuestion, setRephrasedQuestion] = useState<string>(question);

  const responseId = streamData?.data?.requestId;
  const streamDataOutputs = streamData?.data?.outputs;

  const abortControllerRef = useRef<null | AbortController>(null);
  const retryRef = useRef<NodeJS.Timeout>();

  const scoreFromStream = !!streamDataOutputs ? Number(streamDataOutputs?.['out-11']) : streamScore;

  const score = isStream ? scoreFromStream : Number(data?.data?.outputs?.['out-11']);

  const hashKey = data?.info?.key || streamData?.info?.key;

  const isRegenerateDisabled = !question || isLoading;

  const handleRephraseQuestion = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRephrasedQuestion(event.target.value);
  };

  const poorResponse = (!isLoading || !isStreamLoading) && typeof score === 'number' && score <= 4 && (
    <div>
      <p className="mb-1 text-sm font-bold">Poor response. Please reframe patient’s question:</p>
      <Common.TextArea rows={2} value={rephrasedQuestion} onChange={handleRephraseQuestion} />
      <p className="mt-1 text-sm font-medium text-white/50">
        Assistant did not give a response that we are confident about. In order to resubmit, you’ll need to rewrite
        their message in your own words.
      </p>
    </div>
  );

  useEffect(() => {
    if (Boolean(poorResponse) && question) {
      setRephrasedQuestion(question);
    }
    if (!poorResponse) {
      setRephrasedQuestion('');
    }
  }, [question, Boolean(poorResponse)]);

  const readyToCallForSuggestions = useMemo(
    () => patientId && question && (isMessageTask || (!isMessageTask && isInputFocusedOnce)),
    [isInputFocusedOnce, isMessageTask, patientId, question],
  );

  const generateSuggestion = useCallback(
    (force: boolean = false) => {
      abortControllerRef.current = new AbortController();

      try {
        if (readyToCallForSuggestions) {
          const request = isStream ? getStreamSuggestedResponse : getSuggestedResponse;
          const text = rephrasedQuestion ? rephrasedQuestion : question;
          const params: SuggestedMessageRequest = {
            body: {
              flow: isPH ? 'PH' : 'other',
              userId: patientId,
              text,
            },
            signal: abortControllerRef.current.signal,
            force,
          };
          request(params)
            .unwrap()
            .then((res) => {
              if (res.info.status === RequestStatus.IN_PROGRESS) {
                setIsSameRequestInProgress(true);
              } else {
                setIsSameRequestInProgress(false);
              }
            });
        }
      } catch (error) {
        console.error('Fetching AI Suggested Response: ', error);
      }
    },
    [readyToCallForSuggestions, isStream, isPH, patientId, question, rephrasedQuestion],
  );

  useEffect(() => {
    if (responseId && !streamDataOutputs) {
      setIsStreamLoading(true);
      socketStaff.on('stackAiStreamChunk', (streamChunk: SuggestionStreamChunk) => {
        if (streamChunk.requestId === responseId && !!streamChunk.outputs?.['out-18']) {
          setStreamResponse((prev) => [...prev, streamChunk]);
        }

        if (streamChunk.requestId === responseId && streamChunk.finished && streamChunk.response?.outputs?.['out-11']) {
          setStreamScore(Number(streamChunk.response?.outputs?.['out-11']));
        }

        if (streamChunk.requestId === responseId && (streamChunk.finished || streamChunk.closed)) {
          setIsStreamLoading(false);
          socketStaff.off('stackAiStreamChunk');
        }
      });
    }

    return () => {
      socketStaff.off('stackAiStreamChunk');
    };
  }, [responseId, streamDataOutputs]);

  useEffect(() => {
    if (isMessageInputFocused) setIsInputFocusedOnce(true);
  }, [isMessageInputFocused]);

  useEffect(() => {
    setIsSuggestionOpen(!!isMessageTask || !!isInputFocusedOnce);
  }, [isInputFocusedOnce, isMessageTask]);

  useEffect(() => {
    generateSuggestion();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [patientId, question, readyToCallForSuggestions]);

  const sendRephrasedQuestionAnalytics = () => {
    hashKey && sendAnalytics({ event: 'isEditRequest', key: hashKey });
  };

  useEffect(() => {
    if (isSameRequestInProgress) {
      retryRef.current = setTimeout(() => generateSuggestion(), 30000);
    }

    return () => {
      if (retryRef.current) {
        clearTimeout(retryRef.current);
      }
    };
  }, [isSameRequestInProgress]);

  const regenerateSuggestion = () => {
    setStreamResponse([]);
    setStreamScore(null);
    generateSuggestion(true);

    if (!!poorResponse && rephrasedQuestion && rephrasedQuestion !== question) {
      sendRephrasedQuestionAnalytics();
    }
  };

  const suggestedResponse =
    isStream && streamDataOutputs
      ? streamDataOutputs?.['out-18']
      : isStream && !streamDataOutputs
        ? [...streamResponse]
            .sort((a, b) => a.order - b.order)
            .map((chunk) => chunk.outputs?.['out-18'])
            .join('')
        : data?.data?.outputs?.['out-18'];

  useEffect(() => {
    suggestedResponse && dispatch(setMessageSuggestion(suggestedResponse));
    hashKey && dispatch(setHashKey(hashKey));

    return () => {
      dispatch(resetAIasistantState());
    };
  }, [suggestedResponse]);

  const error = (isError || isStreamError) && (
    <div className="flex justify-center gap-1 text-base font-medium text-white/70">
      <Common.Icon name="error-outline" />
      Something went wrong. Please try to regenerate the response.
    </div>
  );

  const content = error || poorResponse || suggestedResponse || '';

  const isPasteDisabled = !suggestedResponse || isLoading || (typeof score === 'number' && score <= 4);

  const topMenuClasses = classNames('flex items-center justify-between', {
    'mb-4': isSuggestionOpen,
    'gap-4': !isSuggestionOpen,
  });

  const regenerateButtonClasses =
    'flex items-center justify-center gap-1 rounded-lg bg-black/20 px-4 py-2 text-sm font-bold text-white';

  return (
    <div className="mt-2 rounded-xl bg-[#0B4D4B] p-4">
      <div className={topMenuClasses}>
        <SuggestionScore score={score} />
        {!isSuggestionOpen &&
          renderContent({
            isOpen: isSuggestionOpen,
            content,
            isLoading: isStreamLoading || isLoading || isSameRequestInProgress,
            isStream,
          })}

        {isSuggestionOpen ? (
          <PasteResponse content={suggestedResponse ?? ''} isDisabled={isPasteDisabled} />
        ) : (
          <ToggleSuggestion isOpen={isSuggestionOpen} setIsOpen={setIsSuggestionOpen} />
        )}
      </div>

      <AnimatePresence initial={isSuggestionOpen}>
        {isSuggestionOpen && (
          <motion.div
            key="content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="overflow-hidden"
          >
            {renderContent({
              isOpen: isSuggestionOpen,
              content,
              isLoading: isStreamLoading || isLoading || isSameRequestInProgress,
              isStream,
            })}

            <div className="mt-4 flex items-center justify-between">
              <button
                className={regenerateButtonClasses}
                disabled={isRegenerateDisabled}
                onClick={regenerateSuggestion}
                type="button"
              >
                <Common.Icon name="repeat" className="size-4" />
                {!!poorResponse ? 'Resubmit' : 'Regenerate'}
              </button>

              <ToggleSuggestion isOpen={isSuggestionOpen} setIsOpen={setIsSuggestionOpen} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Suggestions;

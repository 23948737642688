import { BodyImageDocument, Identity, IdentityDocument } from 'models/tasks.types';
import { ObjectValues } from 'utils/common/types';

export const IdentityFileCategories = {
  USER_IDENTITY: 'user-identity',
  BODY_IMAGE: 'body-image',
} as const;

export type IdentityFileCategory = ObjectValues<typeof IdentityFileCategories>;

export interface IdentityFileProps {
  file?: IdentityDocument | BodyImageDocument;
  type?: 'selfPortrait' | 'governmentIssuedId';
  onChangeIdentityImg?: (newIdentityInfo: Partial<Identity>) => void;
  onChangeBodyImg?: (newBodyImg: BodyImageDocument | null, documentId?: string) => void;
  patientId: string;
  imageClasses?: string;
  onOpenGallery: () => void;
  label?: string;
  category: IdentityFileCategory;
}

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { useParsedURLSearchParams } from 'hooks/common/useParsedSearchParams';
import { useSelectProspectPatientDetails } from 'hooks/components/ProspectDetails/useSelectProspectPatientDetails';
import { closeModal } from 'store/modal/modalSlice';

import { ProspectDetailsProps } from './prospectDetails.types';
import ProspectTabs from './ProspectTabs';

const ProspectDetails: React.FC<ProspectDetailsProps> = ({ patientId, handleCall }) => {
  const { queryParams } = useParsedURLSearchParams();
  const { result } = useSelectProspectPatientDetails({ queryParams, patientId });

  const methods = useForm({ reValidateMode: 'onChange' });
  const dispatch = useDispatch();

  const buttonClasses = classNames(
    'border rounded-lg border-gray-200 flex justify-center items-center text-gray-700 w-32 h-8',
    { 'bg-gray-200 text-gray-500': !result?.phone },
    { 'bg-gray-200 text-gray-500': !result?.prospectStatus },
  );

  return (
    <div data-testid="prospect_details_popup" className="p-6">
      {/* Modal Header */}
      <div className="flex justify-between">
        <h1 className="text-xl font-bold text-gray-700">Prospect: {result?.patientName}</h1>
        <div className="flex flex-row gap-3">
          <button data-testid="call_btn" disabled={!result?.phone} onClick={handleCall} className={buttonClasses}>
            <Common.Icon name="phone-filled" className="mr-1 size-4" />
            <span className="text-sm font-bold">Call prospect</span>
          </button>
          {result?.prospectStatus !== 'Ordered' ? (
            <Link to={`/cross-sell?userId=${result?._id}`} onClick={() => dispatch(closeModal())}>
              <button
                data-testid="order_btn"
                className="flex h-8 w-20 items-center justify-center rounded-lg border border-gray-200"
              >
                <Common.Icon name="checkout" className="mr-1 size-4 text-gray-700" />
                <span className="text-sm font-bold text-gray-700">Order</span>
              </button>
            </Link>
          ) : (
            <span>
              <button
                data-testid="order_btn"
                className="flex h-8 w-20 items-center justify-center rounded-lg border border-gray-200 bg-gray-200 text-gray-500"
              >
                <Common.Icon name="checkout" className="mr-1 size-4 text-gray-700" />
                <span className="text-sm font-bold text-gray-700">Order</span>
              </button>
            </span>
          )}
        </div>
      </div>
      <FormProvider {...methods}>
        <ProspectTabs patientId={patientId} />
      </FormProvider>
    </div>
  );
};

export default ProspectDetails;

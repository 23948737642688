import { useEffect, useRef } from 'react';

import { PopoverButton } from '@headlessui/react';
import { Common } from '@thecvlb/design-system';
import { useDispatch } from 'react-redux';
import { useKeyPress } from 'react-use';

import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import { useAppliedFilters } from 'hooks/filters/useAppliedFilters';
import { closeModal } from 'store/modal/modalSlice';

import { FooterProps } from './footer.types';

const Footer: React.FC<FooterProps> = ({ onClick, appliedFiltersTagsOptions, teamsOptions }) => {
  const dispatch = useDispatch();
  const [isPressed] = useKeyPress('Enter');
  const applyBtnRef = useRef<HTMLButtonElement>(null);
  const { appliedFiltersCount } = useAppliedFilters();

  useEffect(() => {
    if (isPressed && applyBtnRef.current) {
      applyBtnRef.current.click();
    }
  }, [isPressed]);

  const textFilterCount = () => {
    return appliedFiltersCount === 1
      ? `${appliedFiltersCount} filter applied`
      : `${appliedFiltersCount} filters applied`;
  };

  return (
    <>
      <div>
        {!!appliedFiltersCount && <h2 className="mb-2 mt-5 text-sm text-gray-500">{textFilterCount()}</h2>}
        <AppliedFiltersTags tagsOptions={appliedFiltersTagsOptions} teamsOptions={teamsOptions} />
      </div>
      <div className="mt-8 flex items-center justify-between gap-9">
        <div className="flex items-center gap-2">
          <PopoverButton as={Common.Button} type="button" color="white" preIcon="reset" onClick={onClick} size="sm">
            Reset filters
          </PopoverButton>
        </div>
        <div className="flex gap-2">
          <PopoverButton
            as={Common.Button}
            type="button"
            color="white-alt"
            onClick={() => dispatch(closeModal())}
            size="sm"
          >
            Cancel
          </PopoverButton>

          <PopoverButton as={Common.Button} type="submit" color="blue" size="sm" ref={applyBtnRef}>
            Apply
          </PopoverButton>
        </div>
      </div>
    </>
  );
};

export default Footer;

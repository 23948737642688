import { Common } from '@thecvlb/design-system/lib/src';
import { useDispatch } from 'react-redux';

import InternalNoteTemplatesModal from 'components/modals/InternalNoteTemplates/InternalNoteTemplates';
import { openModal } from 'store/modal/modalSlice';

const EditCell: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex gap-1">
      <button
        data-testid="pencil_icon"
        onClick={() =>
          dispatch(openModal({ modalContent: <InternalNoteTemplatesModal id={id} />, size: 'lg', hideClose: true }))
        }
      >
        <Common.Icon name="pencil" className="size-4 hover:text-primary-500" />
      </button>
    </div>
  );
};

export default EditCell;

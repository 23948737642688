import { Common } from '@thecvlb/design-system';

import { notifyError } from 'components/common/Toast/Toast';
import { ACCEPT_CHAT_EXTENSIONS, DOC_EXTENSIONS, VIDEO_EXTENSIONS } from 'constants/extensions';

import { ChatInputFileProps } from './chatInputFile.types';

const ChatInputFile: React.FC<ChatInputFileProps> = ({ onChange, filesSelected, multiple = false }) => {
  const filesLimit = 10;

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target.files;

    if (!files) return;

    const countOfAvailableFiles = filesLimit - filesSelected.length;

    if (files.length > countOfAvailableFiles) {
      notifyError('Only 10 files can be uploaded at a time');
    }

    [...files].slice(0, countOfAvailableFiles).forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const extensionIndex = file.name.lastIndexOf('.');
        const fileExtension = file.name.substring(extensionIndex).toLowerCase();
        if (fileExtension === '.heic' || fileExtension === '.heif') {
          notifyError(`We aren't support ${fileExtension} format please chose supported one`);
        } else {
          const preview = DOC_EXTENSIONS.includes(fileExtension)
            ? 'document'
            : VIDEO_EXTENSIONS.includes(fileExtension)
              ? 'video'
              : reader.result;
          onChange(file, preview);
        }
        e.target.value = '';
      };
      reader.readAsDataURL(file);
    });
  };

  const styleAttach = location.pathname.includes('videoCall');

  return (
    <label
      data-testid="file_uploader"
      className={
        styleAttach
          ? 'mr-1 cursor-pointer'
          : 'flex cursor-pointer items-center gap-1 rounded-lg bg-gray-800/5 px-4 py-[7.5px] text-sm font-bold'
      }
    >
      <Common.Icon name="upload" className="size-4" />
      <input
        className="hidden"
        type="file"
        data-testid="file_uploader_input"
        accept={ACCEPT_CHAT_EXTENSIONS}
        onChange={onChangeFile}
        multiple={multiple}
      />
      {!styleAttach && 'Attach'}
    </label>
  );
};

export default ChatInputFile;

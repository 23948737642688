import dayjs from 'dayjs';

import { BenefitsStatus, MIFStatus } from 'enums/taskDetailsStatus';
import { TaskBody } from 'models/tasks.types';

import { StatusObject } from './detailsLabel.types';

export const getPatientStatus = (
  benefitsStatus?: BenefitsStatus,
  mifDetails?: TaskBody['mifDetails'],
): StatusObject => {
  const MIFStatusNormalValue: StatusObject = { title: 'MIF normal', icon: 'list-view-outline', statusIcon: 'check' };
  const benefitsIncomplete: StatusObject = {
    title: 'Benefits incomplete',
    icon: 'card',
    statusIcon: 'dots-horizontal',
    type: 'warning',
  };

  if (mifDetails?.status) {
    switch (mifDetails?.status) {
      case MIFStatus.Normal:
        return MIFStatusNormalValue;
      case MIFStatus.Warnings:
        return {
          title: 'MIF warnings',
          icon: 'list-view-outline',
          statusNumber: mifDetails?.warningCount,
          type: 'error',
        };
      default:
        return MIFStatusNormalValue;
    }
  }
  switch (benefitsStatus) {
    case BenefitsStatus.BenefitsComplete:
      return { title: 'Insurance complete', icon: 'card', statusIcon: 'check' };
    case BenefitsStatus.BenefitsIncomplete:
      return benefitsIncomplete;
    case BenefitsStatus.IncorrectInsurance:
      return { title: 'Bad address', icon: 'map-point', statusIcon: 'error', type: 'error' };
    case BenefitsStatus.MissingInsurance:
      return { title: 'Missing insurance', icon: 'card', statusIcon: 'error', type: 'error' };
    default:
      return benefitsIncomplete;
  }
};

export const getReminderText = (date: string) => {
  const now = dayjs();
  const targetDate = dayjs(date);

  const diffInMinutes = targetDate.diff(now, 'minutes');
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInMinutes / (60 * 24));
  const duration = diffInDays >= 1 ? diffInDays : diffInHours >= 1 ? diffInHours : diffInMinutes;
  const durationUnit = diffInDays >= 1 ? 'days' : diffInHours >= 1 ? 'hours' : 'minutes';

  return (
    <span className="whitespace-nowrap font-bold">Remind in {dayjs.duration(duration, durationUnit).humanize()}</span>
  );
};

import { useEffect, useRef, useState } from 'react';

import { StarIcon } from '@heroicons/react/20/solid';
import { Common } from '@thecvlb/design-system';

import { ACCEPT_PROFILE_IMG_EXTENSIONS } from 'constants/extensions';
import { useChangeImage } from 'hooks/components/StaffMember/useChangeImage';

import { AdvancedHeaderProps } from './AdvancedHeader.types';
import { handleAvatarChange, handleClick } from '../staffMember.settings';

const AdvancedHeader: React.FC<AdvancedHeaderProps> = ({ staffData, staffImage, handleLogoUpdate, id }) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [imgSrc, setImgSrc] = useChangeImage({ selectedFile });
  const containerRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (staffImage) {
      setImgSrc(staffImage);
    }
  }, [staffImage, setImgSrc]);

  return (
    <div className="relative mb-4 flex justify-between">
      <div className="flex items-center">
        <label htmlFor="uploadAvatar" className="cursor-pointer">
          <button onClick={() => handleClick({ containerRef })}>
            <Common.ProfileImage
              src={typeof imgSrc === 'string' && imgSrc.length ? imgSrc : staffImage}
              edit={true}
              size="lg"
            />
          </button>

          <input
            id="uploadAvatar"
            style={{ display: 'none' }}
            accept={ACCEPT_PROFILE_IMG_EXTENSIONS}
            ref={containerRef}
            type="file"
            multiple={false}
            onChange={(event) => handleAvatarChange({ event, setSelectedFile, handleLogoUpdate })}
          />
        </label>

        <div className="ml-3">
          <p data-testid="user_name_heading" className="flex text-xl font-bold">
            {staffData.displayName}
            {staffData.rating && (
              <div className="my-auto ml-4 flex h-max items-end justify-center rounded-lg bg-secondary px-1.5 py-1 text-white">
                <span className="text-xs text-white">{staffData.rating}</span>
                <StarIcon className="ml-1 size-3.5" />
              </div>
            )}
          </p>

          <p data-testid="user_role" className="text-sm text-gray">
            {staffData.userRoleInfo.name}
          </p>
        </div>
      </div>
      {id && (
        <p data-testid="user_id" className="text-sm">
          ID: {id}
        </p>
      )}
    </div>
  );
};

export default AdvancedHeader;

import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import ControlledSelect from 'components/common/form/ControlledSelect';
import ControlledTextArea from 'components/common/form/ControlledTextArea';
import { DEFAULT_APPOINTMENT_TYPE, DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE } from 'constants/appointmentType';
import { useGetPatientAppointmentTypesQuery } from 'store/crossSell/crossSellSlice';

import { AppointmentTypeFormDataProps } from './appointmentType.types';

const AppointmentType: React.FC<{ isConfirmed: boolean; isWMPlan: boolean }> = ({ isConfirmed, isWMPlan }) => {
  const { control, setValue, watch } = useFormContext<AppointmentTypeFormDataProps>();
  const { data } = useGetPatientAppointmentTypesQuery({ params: 'limit=50' });

  const formattedAppointmentTypes =
    data?.map((item) => ({
      label: item.displayName,
      value: item._id,
    })) ?? [];

  // TODO: Add weight management appointment option properly from query
  const weightManagementApptType = [
    {
      label: DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE,
      value: import.meta.env.VITE_APP_TITLE === 'development' ? '62130d091f6e0c73784efa74' : '6363505dda1bf5f99081cc79',
    },
  ];

  // Check if weight management appointment type already exists
  const hasWeightManagement = formattedAppointmentTypes.some((item) => {
    return item.label === DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE;
  });

  const appointmentTypes = hasWeightManagement
    ? formattedAppointmentTypes
    : [...formattedAppointmentTypes, ...weightManagementApptType];

  const currentAppointmentType = watch('appointmentType');
  const showAppointmentDesc = currentAppointmentType?.label !== DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE;

  useEffect(() => {
    if (!appointmentTypes?.length) return;
    const defaultItem = appointmentTypes.find((appointment) => {
      const appointmentType = isWMPlan ? DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE : DEFAULT_APPOINTMENT_TYPE;
      return appointment.label.toLowerCase().trim() === appointmentType.trim().toLowerCase();
    });

    if (defaultItem) {
      return setValue('appointmentType', defaultItem);
    }
  }, [isWMPlan, data]);

  useEffect(() => {
    if (isWMPlan) {
      setValue('appointmentDesc', '');
    }
  }, [isWMPlan, setValue]);

  return (
    <>
      {isConfirmed && (
        <div data-testid="appointment_type_section" className="mt-4">
          <ControlledSelect
            control={control}
            options={appointmentTypes}
            rules={{
              required: {
                value: true,
                message: 'Appointment type is required',
              },
            }}
            label="Type"
            name="appointmentType"
            className="mb-4"
          />
          {showAppointmentDesc && (
            <ControlledTextArea
              control={control}
              name="appointmentDesc"
              label="Please describe the reason for your visit..."
              cols={10}
              rows={5}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AppointmentType;

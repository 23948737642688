import React from 'react';

import classNames from 'classnames';

import Appointment from 'assets/icons/events/Appointment.svg?react';
import DayOffIcon from 'assets/icons/events/DayOff.svg?react';
import PrimarySpecialized from 'assets/icons/events/Primary&Specialized.svg?react';
import QueueIcon from 'assets/icons/events/Queue.svg?react';
import WeightManagementIcon from 'assets/icons/events/WeightManagement.svg?react';
import { ShiftType } from 'enums/event';

// TODO: Add tests for this component
const EventIcon: React.FC<{ title?: string; shouldViewMargin?: boolean }> = ({ title, shouldViewMargin }) => {
  const singleIconClasses = classNames('h-2 w-2 shrink-0', { 'mr-1': shouldViewMargin });

  if (title === ShiftType.PRIMARY_CARE) {
    return <Appointment className={singleIconClasses} />;
  } else if (title === ShiftType.SPECIALIZED) {
    return <WeightManagementIcon className={singleIconClasses} />;
  } else if (title === ShiftType.PRIMARY_CARE_AND_SPECIALIZED || title === ShiftType.FLOATER) {
    return <PrimarySpecialized className="mt-1 size-3 shrink-0" />;
  } else if (title === ShiftType.DAY_OFF || title === ShiftType.BREAK) {
    return <DayOffIcon className={singleIconClasses} />;
  } else if (
    title === ShiftType.ALL_TYPES ||
    title === ShiftType.ALL_TYPES_AVAILABLE_TO_BOOK ||
    title === ShiftType.ALL_TYPES_UNAVAILABLE_TO_BOOK
  ) {
    return (
      <div className="relative mt-1 flex h-2 w-4 shrink-0">
        <Appointment className="absolute left-0 top-0 size-2" />
        <QueueIcon className="absolute left-1 top-0 size-2" />
        <WeightManagementIcon className="absolute left-2 top-0 size-2" />
      </div>
    );
  }

  return <></>;
};

export default EventIcon;

import React, { useCallback } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ActiveModifiers, useInput } from 'react-day-picker';
import { useClickAway, useToggle } from 'react-use';

import { FRONTEND_DATE_FORMAT } from 'constants/dateFormat';

import { DateInputWrapper } from './dateInput.styles';
import { DateInputProps } from './dateInput.types';
import { StyledDayPicker } from '../../Calendar/calendar.styled';

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  (
    {
      labelTitle,
      name = 'birth',
      labelClasses = 'mr-12 flex items-center mb-4 text-sm',
      inputClasses,
      format = FRONTEND_DATE_FORMAT,
      wrapperInputClasses = 'relative',
      containerClasses,
      setValue,
      onChange,
      onFocus,
      onBlur,
      value,
      errors,
      dataTestId,
      startYear,
      lastYear,
    },
    ref,
  ) => {
    const [localValue, setLocalValue] = React.useState(dayjs(value).toDate());
    const { inputProps, dayPickerProps } = useInput({
      defaultSelected: new Date(),
      fromMonth: startYear || dayjs('1900-01-01').toDate(),
      toMonth: lastYear || dayjs().toDate(),
      format: 'P',
    });
    const [show, toggle] = useToggle(false);

    const containerRef = React.useRef(null);

    useClickAway(containerRef, () => {
      toggle(false);
    });

    const errorMessageTextClasses = 'text-red text-sm mt-1.5';

    const handleChange = useCallback(
      (day: Date) => {
        if (day) {
          setValue(name, dayjs(day).format(format));
          onChange && onChange(dayjs(day).format(format));
          toggle(false);
        }
      },
      [format, name, onChange, setValue, toggle],
    );

    const handleClick = () => {
      toggle();
    };

    const handleDayClick = React.useCallback(
      (day: Date, activeModifiers: ActiveModifiers, event: React.MouseEvent<Element, MouseEvent>) => {
        if (dayPickerProps && dayPickerProps.onDayClick) dayPickerProps?.onDayClick(day, activeModifiers, event);
        handleChange(day);
      },
      [dayPickerProps, handleChange],
    );

    const disabled = React.useMemo(
      () => [
        {
          before: startYear || dayjs('1900-01-01').toDate(),
          after: lastYear || dayjs().toDate(),
        },
      ],
      [lastYear, startYear],
    );

    React.useEffect(() => {
      setLocalValue(dayjs(value).toDate());
    }, [value]);

    React.useEffect(() => {
      show ? onFocus?.() : onBlur?.();
    }, [show, onFocus, onBlur]);

    return (
      <>
        <div className={classNames('flex justify-between', containerClasses)}>
          {labelTitle ? <label className={labelClasses}>{labelTitle}</label> : null}
          <DateInputWrapper data-testid={dataTestId} className={wrapperInputClasses} ref={containerRef}>
            <div className="relative pt-3 text-sm">
              <div onClick={handleClick} className="relative text-sm">
                <Common.Icon name="calendar" className="absolute top-0 z-[1] ml-3 size-5" />
                <input
                  {...inputProps}
                  value={inputProps.value}
                  placeholder={inputProps.placeholder}
                  className={inputClasses}
                  readOnly={true}
                  ref={ref}
                />
                <Common.Icon
                  name={show ? 'arrow-up' : 'arrow-down'}
                  className="absolute right-3 top-0 ml-1.5 size-5 text-gray-700"
                />
              </div>

              {show && (
                <StyledDayPicker
                  {...dayPickerProps}
                  className="absolute z-10 rounded-md bg-white p-4 shadow"
                  onDayClick={handleDayClick}
                  selected={localValue}
                  disabled={disabled}
                />
              )}
            </div>
            {errors && (
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <p className={errorMessageTextClasses}>{message}</p>}
              />
            )}
          </DateInputWrapper>
        </div>
      </>
    );
  },
);
export default React.memo(DateInput);

import { Suspense, useCallback, useEffect, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

import EmptyChatPlaceholder from 'components/common/Chat/EmptyChatPlaceholder';
import RetryBlock from 'components/common/RetryBlock';
import { Status } from 'enums/messages';
import { useChatScroll } from 'hooks/common/useChatScroll';
import { useAppSelector } from 'hooks/redux';
import {
  selectFormattedStaffNotes,
  selectStaffNotes,
  useCreateStaffNoteMutation,
  useLazyInitStaffNotesQuery,
} from 'store/staffNotes/staffNotesSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { lazyWithRetries } from 'utils/common/lazy';

import StaffNotesList from './StaffNotesList';

const loadImageGallery = () => import('components/modals/TaskPopover/TaskDetails/ImageGallery');
const ImageGallery = lazyWithRetries(loadImageGallery);

const selectStaffNotesContainerState = createSelector(
  [selectTask, selectFormattedStaffNotes, selectStaffNotes],
  (task, formattedStaffNotes, staffNotes) => ({
    taskDetailsPatientId: task.taskDetails.patientId,
    staffNotes: formattedStaffNotes,
    images: staffNotes.images,
    staffNotesLoadingStatus: staffNotes.staffNotesLoadingStatus,
  }),
);

const StaffNotesContainer: React.FC<{ isTaskModal?: boolean }> = ({ isTaskModal }) => {
  const [, { isUninitialized, data }] = useCreateStaffNoteMutation({
    fixedCacheKey: 'shared-create-staff-note',
  });
  const { taskDetailsPatientId, staffNotes, images, staffNotesLoadingStatus } =
    useAppSelector(selectStaffNotesContainerState);
  const { patientId = '' } = useParams<{ patientId: string }>();
  const id = taskDetailsPatientId || patientId;
  const [initStaffNotes] = useLazyInitStaffNotesQuery();

  const [message, setMessage] = useState<string>('');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const ref = useChatScroll(message);

  const containerClasses = `relative mt-6 flex ${
    isTaskModal ? 'h-96 max-h-96' : 'grow'
  }   flex-col-reverse overflow-y-auto overflow-x-hidden px-4 py-0`;
  const isRejected = staffNotesLoadingStatus === Status.Rejected;
  const showDefaultMessage = staffNotesLoadingStatus === Status.Fulfilled && isEmpty(staffNotes);

  const handleImageClick = useCallback(
    (filePath: string) => {
      const index = images.indexOf(filePath);
      setPhotoIndex(index);
      setIsOpen(true);
    },
    [images],
  );

  const handleRetry = useCallback(() => {
    if (id) initStaffNotes({ id, limit: 10, pageNo: 0 });
  }, [initStaffNotes, id]);

  useEffect(() => {
    if (!isUninitialized && data?.note) setMessage(data.note);
  }, [data, isUninitialized]);

  return (
    <div data-testid="scroll_area" className={containerClasses} ref={ref}>
      <div className="mb-6">
        {showDefaultMessage ? (
          <EmptyChatPlaceholder isChannelAvailable />
        ) : isRejected ? (
          <RetryBlock handleClick={handleRetry} />
        ) : (
          <StaffNotesList handleImageClick={handleImageClick} />
        )}
      </div>
      <Suspense fallback="Loading...">
        {!isEmpty(images) ? (
          <ImageGallery
            images={images}
            photoIndex={photoIndex}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setPhotoIndex={setPhotoIndex}
          />
        ) : null}
      </Suspense>
    </div>
  );
};

export default StaffNotesContainer;

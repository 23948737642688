import { FUTURE_TASKS_FILTERS, SENT_REQUEST_FILTER_DATA, TASK_CATEGORIES_FILTER_POPUP } from 'constants/taskFilter';
import { Role } from 'enums/role';

import { TasksFiltersFormProps } from './tasksFiltersForm.types';

export const filtersByRoles = (userRole: string, isSentRequests?: boolean) =>
  (isSentRequests ? SENT_REQUEST_FILTER_DATA : TASK_CATEGORIES_FILTER_POPUP).filter((item) => {
    if (item.roles) {
      return item.roles.includes(userRole as Role);
    }
  });

export const defaultValues: TasksFiltersFormProps = {
  patientNames: [],
  searchKey: '',
  doctorName: { label: '', value: '', id: '' },
  dueDate: '',
  localTz: '',
  assignedToNames: [],
  category: [],
  startCreateDate: '',
  endCreateDate: '',
  createdAt: '',
  status: '',
  futureTasksRange: { value: '', label: '' },
  startDueDate: '',
  endDueDate: '',
  patientStatuses: '',
  dynamicStatus: '',
  onboardingStatuses: [],
  patientTags: [],
  taskTags: [],
  patientStates: [],
  escalated: false,
  audiences: [],
  teams: [],
};

export const getTasksFiltersValue = (
  key: string,
  val: string,
  getListPatientsName: () => { value: string; label: string }[],
) => {
  let currentValue;
  switch (key) {
    case 'patientNames':
      const list = getListPatientsName();
      if (!list.length) {
        break;
      }
      if (Array.isArray(val)) {
        currentValue = [];
        val.forEach((patientName) => {
          currentValue.push(list?.find((item) => item.value === patientName));
        });
      } else {
        currentValue = list?.find((item) => item.value === val);
      }
      break;
    case 'futureTasksRange':
      currentValue = FUTURE_TASKS_FILTERS.find((futureTaskItem) => futureTaskItem.value === val);
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};

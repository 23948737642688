import * as Checkbox from '@radix-ui/react-checkbox';
import { createSelector } from '@reduxjs/toolkit';
import { DataItemProps } from '@thecvlb/design-system/lib/src/common/Tabs/tabs.types';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { Role } from 'enums/role';
import { TaskCategories } from 'enums/taskCategories';
import { useAppSelector } from 'hooks/redux';
import { selectTasks } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { formatCategoryLabel } from 'utils/formatCategoryLabel';

import { TaskCategoriesFilterProps } from './taskCategoriesFilter.types';

const selectTaskCategoriesFilterState = createSelector([selectTasks, selectUser], (tasks, user) => ({
  tasksSummary: tasks.tasksSummary,
  asyncProviderEnabled: tasks.asyncProviderEnabled,
  userRole: user.userType.name,
}));

const TaskCategoriesFilter: React.FC<TaskCategoriesFilterProps> = ({ categories }) => {
  const { tasksSummary, asyncProviderEnabled, userRole } = useAppSelector(selectTaskCategoriesFilterState);

  const [searchParams, setSearchParams] = useSearchParams();

  const history = categories.find((item) => item.label.toLowerCase() === 'history');

  // Filter task categories by extra conditions
  const filteredCategories = categories.filter((category) => {
    if (!asyncProviderEnabled && userRole === Role.PH && category.label === TaskCategories.Async) {
      return false; // Skip Async category if it is not enabled for PH
    }
    return true;
  });

  const selectedCategories = searchParams.getAll('category');

  const isChecked = (item: DataItemProps) => {
    const isAllCategoryChecked = selectedCategories.length === 0 && item.label.toLowerCase() === 'all';

    if (isAllCategoryChecked) return true;

    return selectedCategories.includes((item?.value as { name: string; param: string }).name);
  };

  /**
   * @description
   * Toggle selected category in URL
   */
  const handleChange = (checkedCategory: DataItemProps) => {
    const selectedCategory = (checkedCategory.value as { name: string; param: string }).name;

    // Get the existing selected categories or an empty array

    if (selectedCategory.toLowerCase() === 'all') {
      searchParams.delete('category');
      setSearchParams(searchParams);
      return;
    }
    // Toggle the selected category
    if (selectedCategories.includes(selectedCategory)) {
      // Category is selected, so remove it
      const updatedCategories = selectedCategories.filter((category) => category !== selectedCategory);
      searchParams.delete('category');
      updatedCategories.forEach((category) => searchParams.append('category', category));
    } else {
      // Category is not selected, so add it
      searchParams.append('category', selectedCategory);
    }

    setSearchParams(searchParams);
  };

  const handleSelectHistory = (isSelectHistory: Checkbox.CheckedState) => {
    if (history && isSelectHistory) {
      handleChange(history);
      searchParams.set('sortField', 'dueDate');
      searchParams.set('sortOrder', 'DESC');
    } else if (history) {
      handleChange(history);
      searchParams.delete('sortField');
      searchParams.delete('sortOrder');
    }
    setSearchParams(searchParams);
  };

  const getCheckboxClasses = (isActive: boolean) =>
    classNames(
      'flex items-center justify-center whitespace-nowrap rounded-full px-4 py-1.5 text-sm font-semibold gap-2',
      isActive ? 'bg-secondary hover:bg-secondary-600 text-white' : 'bg-gray-100 hover:bg-gray-200',
    );

  const getCount = (label: string) => {
    const key = label.replace(/[\s•]/g, '');
    if (!tasksSummary) return;
    const count = tasksSummary[(key.toLowerCase() + 'Count') as keyof typeof tasksSummary] || '';
    if (!count) return;
    return <span className="rounded-full bg-black/5 p-0.5 px-[5px] text-xs">{count > 99 ? '99+' : count}</span>;
  };

  return (
    <div data-testid="tabs_filter_menu" className="flex flex-wrap gap-2">
      {filteredCategories.map((category) => {
        if (category.label.toLocaleLowerCase() === 'history') return;
        return (
          <Checkbox.Root
            value={category.label}
            key={category.label}
            onCheckedChange={() => handleChange(category)}
            checked={isChecked(category)}
            className={getCheckboxClasses(isChecked(category))}
          >
            <div className={`size-2 rounded-full border border-white ${category.color}`} />
            {formatCategoryLabel(category.label)}
            {getCount(category.label)}
          </Checkbox.Root>
        );
      })}
      {history && (
        <Checkbox.Root
          value={history.label}
          key={history.label}
          onCheckedChange={(isActive) => handleSelectHistory(isActive)}
          checked={isChecked(history)}
          className={getCheckboxClasses(isChecked(history))}
        >
          <div className={`size-2 rounded-full border border-white ${history.color}`} />
          {history.label}
        </Checkbox.Root>
      )}
    </div>
  );
};

export default TaskCategoriesFilter;

import { Common } from '@thecvlb/design-system';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import EditOrder from 'components/order/EditOrder';
import { PathName } from 'enums/pathName';
import { openModal } from 'store/modal/modalSlice';

const EditCell: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex gap-1">
      <Link
        onClick={(e) => {
          e.stopPropagation();
        }}
        data-testid="eye_icon"
        to={PathName.Orders + '/' + id}
      >
        <Common.Icon name="view-case" className="size-4 hover:text-primary-500" />
      </Link>
      <button
        data-testid="pencil_icon"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(openModal({ modalContent: <EditOrder orderId={id} />, size: 'xl' }));
        }}
      >
        <Common.Icon name="pencil" className="size-4 hover:text-primary-500" />
      </button>
    </div>
  );
};

export default EditCell;

import { Fragment } from 'react';

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { Common } from '@thecvlb/design-system';

import { useAppliedFilters } from 'hooks/filters/useAppliedFilters';

const CommonPopover: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { appliedFiltersCount } = useAppliedFilters();
  return (
    <Popover>
      <PopoverButton as={Common.Button} dataTestId="search_btn" color="white-alt" size="md" preIcon="search">
        <span className="whitespace-nowrap text-sm">
          {!!appliedFiltersCount ? `${appliedFiltersCount} applied` : 'Search'}
        </span>
      </PopoverButton>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <PopoverPanel className="absolute right-0 top-0 z-20 mt-10 size-fit rounded-2xl bg-white px-4 shadow-2xl">
          {children}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default CommonPopover;

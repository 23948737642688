import { forwardRef, useEffect, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { Editor } from '@tinymce/tinymce-react/node_modules/tinymce';
import classNames from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useClickAway } from 'react-use';

import { MessageEditor } from 'components/common/Chat/MessageEditor/MessageEditor';
import { handleEditorFocus } from 'components/common/Chat/MessageEditor/messageEditor.settings';
import { UpdatedMessageToServerParams } from 'contexts/MessagesContext/messagesContext.types';
import { useClientMessages } from 'hooks/common/useClientMessages';
import { stripHTML } from 'utils/common/parseHTML';

import { StyledFormWrapper } from './editForm.styled';
import { EditFormProps } from './editForm.types';

const EditForm = forwardRef<HTMLDivElement, EditFormProps>(
  ({ messageId = '', setIsEditing, setEditedText, editedText, originalMessageText, className, type, style }, ref) => {
    const methods = useForm({
      defaultValues: {
        message: '',
      },
    });

    // NOTE: to reset the edited text when the original message text changes
    useEffect(() => {
      setEditedText(originalMessageText);
    }, [originalMessageText]);

    const useMessages = useClientMessages({ type });
    const { sendUpdatedMessageToServer } = useMessages();
    const { control, setValue } = methods;

    const [focus, setFocus] = useState(false);
    const [showButtons, setShowButtons] = useState(false);

    const inputWrapperRef = useRef<HTMLDivElement | null>(null);
    const editorRef = useRef<Editor | null>(null); // To hold the editor instance

    // Use useEffect to show buttons after 200ms
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowButtons(true);
      }, 200);

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }, []);

    const wrapperClasses = classNames('w-full', {
      'ring-transparent': focus,
    });

    const reset = () => {
      setValue('message', '');
    };

    const onFormSubmit = async (message: string) => {
      if (!stripHTML(message).trim().length) {
        return;
      }
      setEditedText(message);
      const params: UpdatedMessageToServerParams = {
        message,
        messageId,
        type,
      };

      sendUpdatedMessageToServer(params);
      reset();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      const isEnter = event.key === 'Enter';

      // Handle message submission on Enter key press
      if (isEnter && !event.shiftKey && !event.ctrlKey && !event.metaKey && !event.altKey) {
        event.preventDefault();
        onFormSubmit(editedText.trim());
        setIsEditing(false);
      }
    };

    useClickAway(inputWrapperRef, () => {
      setFocus(false);
    });

    const handleSaveEdit = () => {
      onFormSubmit(editedText.trim());
      setIsEditing(false);
    };

    const handleCancelEdit = () => {
      setEditedText(originalMessageText);
      setIsEditing(false);
    };

    const contentStyle =
      'html { margin-top: 8px; margin-bottom: -2px; }' +
      'body { font-family: Greycliff CF,Arial,sans-serif; color: #424647; font-size:14px; margin: 6px 5px;  }' +
      'p { margin: 10px 5px; }' +
      'ol { margin: 10px 0px; }' +
      'ul { margin: 10px 0px; }' +
      '.mce-content-body:before { padding: 0px 5px;}';

    return (
      <FormProvider {...methods}>
        <div className={wrapperClasses} ref={inputWrapperRef}>
          <StyledFormWrapper>
            <div className={className} style={style}>
              <Controller
                control={control}
                name="message"
                render={({ field: { onChange, ref: controllerRef } }) => {
                  const handleEditorChange = (enteredValue: string) => {
                    onChange(enteredValue);
                    setEditedText(enteredValue);
                  };

                  return (
                    <div ref={ref} data-testid="message-editor">
                      <MessageEditor
                        minHeight={36}
                        maxHeight={500}
                        autoresizeBottomMargin={10}
                        onEditorChange={handleEditorChange}
                        value={editedText}
                        onInit={(_, editor) => {
                          editorRef.current = editor;
                          controllerRef(editor); // Register the editor instance with react-hook-form
                          handleEditorFocus(editor); // Set cursor at the end when editor initializes
                        }}
                        onKeyDown={handleKeyDown}
                        placeholder="Message patient..."
                        onFocus={() => setFocus(true)}
                        contentStyle={contentStyle}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </StyledFormWrapper>
          <div className="relative">
            <div
              className={classNames('absolute bottom-1 right-3 mb-[6px] flex gap-2 transition-opacity duration-500', {
                'opacity-0': !showButtons,
                'opacity-100': showButtons,
              })}
            >
              <Common.Button
                className="grow justify-center"
                data-testid="cancel-edit"
                color="white-alt"
                type="button"
                size="sm"
                onClick={handleCancelEdit}
              >
                <Common.Icon name="close" className="size-5 text-gray-700" />
              </Common.Button>
              <Common.Button
                className="grow justify-center"
                data-testid="save-edit"
                color="green"
                size="sm"
                onClick={handleSaveEdit}
                type="button"
              >
                <Common.Icon name="check" className="size-5 text-white" />
              </Common.Button>
            </div>
          </div>
        </div>
      </FormProvider>
    );
  },
);

export default EditForm;
